import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { map, switchMap, catchError } from 'rxjs/operators';

import {
  NbOAuth2AuthStrategy,
  NbOAuth2ResponseType,
  NbAuthOAuth2JWTToken,
  NbOAuth2AuthStrategyOptions,
  NbAuthStrategyClass,
  NbAuthResult,
  NbAuthIllegalTokenError,
  decodeJwtPayload,
  NbOAuth2GrantType,
} from '@nebular/auth';


export class OpenIdToken extends NbAuthOAuth2JWTToken {

  // let's rename it to exclude name clashes
  static NAME = 'nb:auth:openid:token';

  getValue(): string {
    return this.token.id_token;
  }

  protected parsePayload() {
    this.payload = decodeJwtPayload(this.token.id_token);
    this.parseAccessTokenPayload();
  }

  protected parseAccessTokenPayload(): any {
    this.accessTokenPayload =  this.token.access_token;
  }

}

@Injectable()
export class OpenIdOAuth2Strategy extends NbOAuth2AuthStrategy {

  protected redirectResultHandlers = {
    [NbOAuth2ResponseType.CODE]: () => of(this.route.snapshot.queryParams).pipe(
        switchMap((params: any) => {
          if (params.code) {
            return this.requestToken(params.code);
          }

          return of(
            new NbAuthResult(
              false,
              params,
              this.getOption('redirect.failure'),
              this.getOption('defaultErrors'),
              [],
            ));
        }),
      ),
    ['id_token token']: () => {
      const module = 'authorize';
      const requireValidToken = this.getOption(`${module}.requireValidToken`);
      return of(this.route.snapshot.fragment).pipe(
        map(fragment => this.parseHashAsQueryParams(fragment)),
        map((params: any) => {
          if (!params.error) {
            return new NbAuthResult(
              true,
              params,
              this.getOption('redirect.success'),
              [],
              this.getOption('defaultMessages'),
              this.createToken(params, requireValidToken));
          }
          return new NbAuthResult(
            false,
            params,
            this.getOption('redirect.failure'),
            this.getOption('defaultErrors'),
            [],
          );
        }),
        catchError(err => {
          const errors = [];
          if (err instanceof NbAuthIllegalTokenError) {
            errors.push(err.message);
          } else {
            errors.push('Something went wrong.');
          }
          return of(
            new NbAuthResult(
              false,
              err,
              this.getOption('redirect.failure'),
              errors,
            ));
        }),
      );
    },
  };


  protected redirectResults: any = {
    [NbOAuth2ResponseType.CODE]: () => of(this.route.snapshot.queryParams).pipe(
        map((params: any) => !!(params && (params.code || params.error))),
      ),
    ['id_token token']: () => of(this.route.snapshot.fragment).pipe(
        map(fragment => this.parseHashAsQueryParams(fragment)),
        map((params: any) => !!(params && (params.id_token || params.error))),
      ),
  };

  authenticate(data?: any): Observable<NbAuthResult> {
    if (this.getOption('token.grantType') === NbOAuth2GrantType.PASSWORD) {
      return this.passwordToken(data.email, data.password);
    } else {
      return this.isRedirectResult().pipe(
        switchMap((result: boolean) => {
          if (!result) {
            this.authorizeRedirect(data);
            return of(new NbAuthResult(true));
          }
          return this.getAuthorizationResult();
        }),
      );
    }
  }

  protected authorizeRedirect(data?: any) {
    this.window.location.href = this.buildRedirectUrl(data);
  }

  protected buildRedirectUrl(data = {}) {
    const params = {
      response_type: this.getOption('authorize.responseType'),
      client_id: this.getOption('clientId'),
      redirect_uri: this.getOption('authorize.redirectUri'),
      scope: this.getOption('authorize.scope'),
      state: this.getOption('authorize.state'),

      ...data,

      ...this.getOption('authorize.params'),
    };

    const endpoint = this.getActionEndpoint('authorize');
    const query = this.urlEncodeParameters(this.cleanParams(params));

    return `${endpoint}?${query}`;
  }

  // we need this method for strategy setup
  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [OpenIdOAuth2Strategy, options];
  }
}
