/**
 * This class is partly copied from
 * https://github.com/akveo/nebular/blob/4202d211fffcc5ceb6207cdc05cc4a95a98a2902/src/framework/date-fns/services/date-fns-date.service.ts.
 */
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { NbNativeDateService } from '@nebular/theme';

import { default as parse } from 'date-fns/parse';
import { default as formatDate } from 'date-fns/format';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';

@Injectable()
export class CustomDateFnsService extends NbNativeDateService {

  dateFormat: string;

  constructor(
    @Inject(LOCALE_ID) locale: string,
  ) {
    super(locale);
    this.dateFormat = getLocaleDateFormat(locale, FormatWidth.Short);
  }

  format(date: Date, format: string): string {
    if (date) {
      return formatDate(date, format || this.dateFormat, { useAdditionalWeekYearTokens: true, useAdditionalDayOfYearTokens: true });
    }

    return '';
  }

  parse(date: string, format: string): Date {
    return parse(date,format || this.dateFormat, new Date(), { useAdditionalWeekYearTokens: true, useAdditionalDayOfYearTokens: true });
  }

  getId(): string {
    return 'custom-date-fns';
  }

  getDateFormat(): string {
    return this.dateFormat;
  }
}
