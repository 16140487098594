<div class="container-fluid">
  <button class="margin-bottom" nbButton routerLink="/user/profile">
    <nb-icon icon="arrow-ios-back-outline"></nb-icon>
    <span translate="buttons.backToProfile"></span>
  </button>
  <nb-card>
    <nb-card-body>
      <div class="row justify-content-center">
        <div class="col align-self-center max-auth-width">
          <h1 id="title" class="title" translate="authPages.reset.title"></h1>
          <p class="sub-title" translate="authPages.change.subtitle"></p>

          <nb-alert
            *ngIf="showMessages.error && errors?.length && !submitted"
            outline="danger"
            role="alert"
          >
            <p class="alert-title">
              <strong translate="authPages.alerts.unsuccessful"></strong>
            </p>
            <ul class="alert-message-list">
              <li *ngFor="let error of errors" class="alert-message">
                {{ error }}
              </li>
            </ul>
          </nb-alert>

          <nb-alert
            *ngIf="showMessages.success && messages?.length && !submitted"
            outline="success"
            role="alert"
          >
            <p class="alert-title">
              <strong translate="authPages.alerts.successful"></strong>
            </p>
            <ul class="alert-message-list">
              <li *ngFor="let message of messages" class="alert-message">
                {{ message }}
              </li>
            </ul>
          </nb-alert>

          <form
            (ngSubmit)="changePass()"
            #resetPassForm="ngForm"
            aria-labelledby="title"
          >
            <div class="form-control-group">
              <label
                class="label"
                for="input-password"
                translate="authPages.change.oldPassword"
              ></label>
              <input
                nbInput
                [(ngModel)]="user.oldPassword"
                #oldPassword="ngModel"
                type="password"
                id="input-old-password"
                name="old-password"
                class="first"
                placeholder="Old Password"
                autofocus
                fullWidth
                fieldSize="large"
                appRequired
                [status]="
              oldPassword.dirty
                ? oldPassword.invalid
                  ? 'danger'
                  : 'success'
                : 'basic'
            "
                [required]="getConfigValue('forms.validation.password.required')"
                [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                [attr.aria-invalid]="
              oldPassword.invalid && oldPassword.touched ? true : null
            "
              />
              <ng-container *ngIf="oldPassword.invalid && oldPassword.touched; else spacer">
                <p
                  class="caption status-danger"
                  *ngIf="oldPassword.errors?.required"
                  translate="authPages.change.oldPasswordRequired"
                ></p>
                <p
                  class="caption status-danger"
                  *ngIf="
                oldPassword.errors?.minlength || oldPassword.errors?.maxlength
              "
                  translate="authPages.reset.passwordPattern"
                  [translateParams]="{
                min: getConfigValue('forms.validation.password.minLength'),
                max: getConfigValue('forms.validation.password.maxLength')
              }"
                ></p>
              </ng-container>
            </div>

            <div class="form-control-group">
              <label
                class="label"
                for="input-password"
                translate="authPages.reset.password"
              ></label>
              <input
                nbInput
                [(ngModel)]="user.password"
                #password="ngModel"
                type="password"
                id="input-password"
                name="password"
                class="first"
                placeholder="New Password"
                autofocus
                fullWidth
                fieldSize="large"
                appRequired
                [status]="
              password.dirty
                ? password.invalid
                  ? 'danger'
                  : 'success'
                : 'basic'
            "
                [required]="getConfigValue('forms.validation.password.required')"
                [minlength]="getConfigValue('forms.validation.password.minLength')"
                [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                [attr.aria-invalid]="
              password.invalid && password.touched ? true : null
            "
              />
              <ng-container *ngIf="password.invalid && password.touched; else spacer">
                <p
                  class="caption status-danger"
                  *ngIf="password.errors?.required"
                  translate="authPages.reset.passwordRequired"
                ></p>
                <p
                  class="caption status-danger"
                  *ngIf="password.errors?.minlength || password.errors?.maxlength"
                  translate="authPages.reset.passwordPattern"
                  [translateParams]="{
                min: getConfigValue('forms.validation.password.minLength'),
                max: getConfigValue('forms.validation.password.maxLength')
              }"
                ></p>
              </ng-container>
            </div>

            <div class="form-group">
              <label
                class="label"
                for="input-re-password"
                translate="authPages.reset.confirm"
              ></label>
              <input
                nbInput
                [(ngModel)]="user.confirmPassword"
                #rePass="ngModel"
                id="input-re-password"
                name="rePass"
                type="password"
                class="last"
                placeholder="Confirm Password"
                fullWidth
                fieldSize="large"
                appRequired
                [status]="
              rePass.touched
                ? rePass.invalid || password.value !== rePass.value
                  ? 'danger'
                  : 'success'
                : 'basic'
            "
                [required]="getConfigValue('forms.validation.password.required')"
                [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null"
              />
              <ng-container *ngIf="rePass.touched; else spacer">
                <p
                  class="caption status-danger"
                  *ngIf="rePass.invalid && rePass.errors?.required"
                  translate="authPages.reset.confirmRequired"
                ></p>
                <p
                  class="caption status-danger"
                  *ngIf="password.value !== rePass.value && !rePass.errors?.required"
                  translate="authPages.reset.confirmPattern"
                ></p>
              </ng-container>
            </div>

            <button
              nbButton
              status="primary"
              fullWidth
              size="large"
              [disabled]="submitted || !resetPassForm.valid || password.value !== rePass.value"
              [class.btn-pulse]="submitted"
              translate="authPages.reset.button"
            ></button>
          </form>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>

<ng-template #spacer>
  <div style="height: 2rem"></div>
</ng-template>
