<nb-spinner *ngIf="showSpinner">
</nb-spinner>

<!-- Join course -->
<div class="container-fluid" *ngIf="!showSpinner && courseDetails && user">
  <h4 [translateParams]="{courseName: courseDetails.name}" translate="course.invite.accept.title" class="margin-bottom"></h4>

  <div class="row">
    <div class="col-12" [class.col-md-6]="courseDetails.enrollmentType !== eCourseEnrollmentType.USER">
      <nb-card class="team-list" *ngIf="courseDetails.enrollmentType === eCourseEnrollmentType.UNRESTRICTED || courseDetails.enrollmentType === eCourseEnrollmentType.USER">
        <nb-card-body>
          <h6 translate="course.invite.accept.useUser"></h6>
          <ng-container *ngIf="user.teams">
            <nb-list>
              <nb-list-item (click)="joinCourseAsUser()">
                <nb-user [name]="user.fullName" [picture]="user.profilePicturePreviewUrl" shape="rectangle"></nb-user>
                <app-badge class="margin-left" status="primary" [text]="'course.invite.accept.alreadyPartOfCourseLabel' | translate" *ngIf="userCourseIds?.includes(courseDetails.id)"></app-badge>
              </nb-list-item>
            </nb-list>
          </ng-container>
        </nb-card-body>
      </nb-card>

      <nb-card class="team-list" *ngIf="user.teams.length > 0 && (courseDetails.enrollmentType === eCourseEnrollmentType.UNRESTRICTED || courseDetails.enrollmentType === eCourseEnrollmentType.TEAM)">
        <nb-card-body>
          <h6 translate="course.invite.accept.useExistingTeam"></h6>
          <nb-list>
            <nb-list-item (click)="joinCourseAsTeam(team.id)" *ngFor="let team of user.teams">
              <nb-user [name]="team.name" [picture]="team.profilePicturePreviewUrl" shape="rectangle"></nb-user>
              <app-badge class="margin-left" status="primary" [text]="'course.invite.accept.alreadyPartOfCourseLabel' | translate" *ngIf="teamCourseIds[team.id]?.includes(courseDetails.id)"></app-badge>
            </nb-list-item>
          </nb-list>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-12" [class.col-md-6]="user.teams.length > 0 || eCourseEnrollmentType.UNRESTRICTED" *ngIf="courseDetails.enrollmentType === eCourseEnrollmentType.UNRESTRICTED || courseDetails.enrollmentType === eCourseEnrollmentType.TEAM">
      <nb-card>
        <nb-card-body>
          <h6 translate="course.invite.accept.useNewTeam"></h6>
          <input [(ngModel)]="teamName" [placeholder]="'Team name'" fullWidth nbInput/>
          <button (click)="createTeam()" fullWidth nbButton status="primary" class="margin-top" [disabled]="!isTeamNameValid() || createTeamLoading">
            <nb-icon icon="plus-square-outline"></nb-icon>
            <span translate="application.teamRequired.createTeam" [translateParams]="{teamName}"></span>
          </button>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
