import { Component, OnInit } from '@angular/core';
import { RoleService } from '../role.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-role-updated-banner',
  templateUrl: './role-updated-banner.component.html',
  styleUrls: ['./role-updated-banner.component.scss']
})
export class RoleUpdatedBannerComponent {

  showPendingChangeBanner$: Observable<boolean>;

  constructor(private roleService: RoleService) {
    this.showPendingChangeBanner$ = this.roleService.pendingPermissionUpdate;
  }

  reload(): void {
    location.reload();
  }
}
