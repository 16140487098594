import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { NbAuthService, NbAuthToken, NbTokenService } from '@nebular/auth';
import { RoleProvider } from './role.provider';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {

  pendingRefreshRequest: Subject<boolean>;

  constructor(
    private nbAuthService: NbAuthService,
    private nbTokenService: NbTokenService,
  ) {
  }


  /**
   * Refreshes the token and emits whether the user is authenticated, after the token was refreshed
   *
   * @param currentToken
   */
  refreshToken(currentToken: NbAuthToken): Observable<boolean> {
    // Check if request is pending, return it if it is
    if (this.pendingRefreshRequest) {
      return this.pendingRefreshRequest;
    }

    // No request pending -> Execute it now
    this.pendingRefreshRequest = new Subject<boolean>();

    return this.nbAuthService.refreshToken(currentToken.getOwnerStrategyName(), currentToken)
      .pipe(
        switchMap(res => {
          if (res.isSuccess()) {
            return this.nbAuthService.isAuthenticated().pipe(tap(isAuthenticated => {
              this.pendingRefreshRequest.next(isAuthenticated);
              this.pendingRefreshRequest = undefined;
            }));
          } else {
            this.pendingRefreshRequest.next(false);
            this.pendingRefreshRequest = undefined;
            return of(false);
          }
        }),
      );
  }

}
