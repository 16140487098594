import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { RoleProvider } from './role.provider';
import { NbAuthService } from '@nebular/auth';
import { OpenIdToken } from './openid-auth-strategy';
import { UserRole } from '../user/user.model';
import { ManagementService } from '../core/services/management.service';
import { RefreshService } from './refresh.service';
import { TeamService } from '../team/team.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  pendingPermissionUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  private lastReceivedRoleFromHeader: string = undefined;
  private lastReceivedRoleFromToken: string = undefined;

  private isAuthenticated = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private nbAuthService: NbAuthService,
    private refreshService: RefreshService,
    private roleProvider: RoleProvider,
    private managementService: ManagementService,
) {
    // Subscribe to navigation events as navigation events allow us to instantly reload the page without notifying the user
    // if the permissions of the user changed (since no data can be passed between different pages except for url params,
    // which will be there even after the refresh)
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.handleNavigate();
      }
    });

    // Get the initial role from the token and check whether it matches the one from the header
    this.fetchAndUpdateLastReceivedRoleFromToken();

    // Whenever the token changes: Get the new role from the token and check whether it matches the one from the header
    this.nbAuthService.onTokenChange().subscribe((token) => {
      this.roleProvider.getRoleFromToken(token as OpenIdToken).subscribe((role) => {
        this.lastReceivedRoleFromToken = role;
        this.checkPendingPermissionUpdateBanner();
      });
    });

    this.nbAuthService.onAuthenticationChange().subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });
  }

  fetchAndUpdateLastReceivedRoleFromToken(): void {
    this.roleProvider.getRole().subscribe(role => {
      this.lastReceivedRoleFromToken = role;
      this.checkPendingPermissionUpdateBanner();

      // We will receive UserRole.GUEST, if the tenant is not yet set
      // -> Retry after 500ms, if this is the case as the tenant should be set by then
      if (role === UserRole.GUEST && !this.managementService.tenant) {
        setTimeout(() => {
          this.fetchAndUpdateLastReceivedRoleFromToken();
        }, 500);
      }
    });
  }

  updateLastReceivedRoleFromHeader(newRole: string): void {
    this.lastReceivedRoleFromHeader = newRole;
    this.checkPendingPermissionUpdateBanner();
  }

  checkPendingPermissionUpdateBanner(): void {
    if (!TeamService.isTeamMode() && this.lastReceivedRoleFromHeader && this.lastReceivedRoleFromToken && this.lastReceivedRoleFromHeader !== this.lastReceivedRoleFromToken && this.lastReceivedRoleFromToken !== UserRole.GUEST && this.isAuthenticated) {
      // Only emit a pending update if roles from both header and token are provided AND they mismatch AND the role is not GUEST (in which case it can not be fixed anyway, since it most likely is due to the tenant not having been set) AND the user is authenticated
      // This will trigger a banner to be shown that informs the user about the updated role
      this.pendingPermissionUpdate.emit(true);
    } else {
      // Remove any existing banners etc. if the conditions are (no longer) fulfilled
      this.pendingPermissionUpdate.emit(false);
    }
  }

  handleNavigate(): void {
    if (!this.lastReceivedRoleFromHeader) {
      // We are not interested if we have not yet received a role from the header since then we can not do any comparisons
      return;
    }

    this.roleProvider.getRole().subscribe(roleFromToken => {
      if (TeamService.isTeamMode()) {
        // Nothing to do for team mode
        return;
      }

      if (roleFromToken === UserRole.GUEST) {
        // This should not happen -> We will ignore it
        return;
      }

      if (roleFromToken !== this.lastReceivedRoleFromHeader) {
        // The roles mismatch -> Request a new token
        this.nbAuthService.getToken().subscribe((token) => {
          this.refreshService.refreshToken(token).subscribe(() => {
            // The token is refreshed, AFTER the current page was loaded -> It has already been checked whether certain elements should be shown to the user
            // These checks might change because of the new token -> We reload the page.
            // NOTE: We do not use 'location.reload()', as this solution is more smooth and will not redownload the chunks but only re-initialize all components
            this.pendingPermissionUpdate.emit(false);
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigateByUrl(this.router.url);
          });
        });
      }
    });
  }
}
