<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <nb-card>
        <nb-card-body>
          <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
            <h2 class="title" translate="not-found.title"></h2>
            <small class="sub-title" translate="not-found.error"></small>
            <button nbButton fullWidth routerLink="" translate="not-found.back-button">
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
