import { Injectable } from '@angular/core';

import { NbAuthService } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { Observable, of, switchMap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserRole } from '../user/user.model';
import { OpenIdToken } from './openid-auth-strategy';
import { ManagementService } from '../core/services/management.service';
import { TeamService } from '../team/team.service';
import { RefreshService } from './refresh.service';

@Injectable()
export class RoleProvider implements NbRoleProvider {
  constructor(
    private authService: NbAuthService,
    private refreshService: RefreshService,
    private managementService: ManagementService,
  ) {}

  getRole(): Observable<string> {
    return this.authService.onTokenChange().pipe(
      first(),
      switchMap((token: OpenIdToken) => this.getRoleFromToken(token))
    );
  }

  public getRoleFromToken(token: OpenIdToken, alreadyRefreshed = false): Observable<string> {
    if (token.getValue() && !token.isValid()) {
      if (!alreadyRefreshed) {
        return this.refreshService.refreshToken(token).pipe(
          switchMap(() => this.authService.getToken().pipe(
              switchMap(newToken => newToken.isValid() ? this.getRoleFromToken(newToken as OpenIdToken, true) : UserRole.GUEST),
            ))
        );
      }
      return of(UserRole.GUEST);
    }

    const teamIdentifier = TeamService.getTeamIdentifier();

    if (teamIdentifier) {
      return of(UserRole.USER); // In Team Mode, users don't have their normal permissions
    }

    if (token.getPayload()?.superAdmin) {
      return of(UserRole.SUPER_ADMIN);
    }

    if (token.getPayload()?.activatedTenants && token.getPayload().activatedTenants[this.managementService.tenant?.id]) {
      const tenantData = token.getPayload().activatedTenants[this.managementService.tenant.id];
      if (typeof tenantData === 'string') {
        // legacy case -> role is stored directly // TODO: Remove this
        return of(tenantData);
      }
      return of(tenantData.role);
    }

    return of(UserRole.GUEST);
  }

  isAtLeastSuperAdmin(): Observable<boolean> {
    return this.getRole().pipe(map((role: string) =>  role === UserRole.SUPER_ADMIN));
  }

  isAtLeastAdmin(): Observable<boolean> {
    return this.getRole().pipe(map((role: string) => role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN));
  }

  isAtLeastInstructor(): Observable<boolean> {
    return this.getRole().pipe(
      map(
        (role: string) =>
          role === UserRole.INSTRUCTOR || role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN
      )
    );
  }

  isAtLeastCoach(): Observable<boolean> {
    return this.getRole().pipe(
      map(
        (role: string) =>
          role === UserRole.COACH || role === UserRole.INSTRUCTOR || role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN
      )
    );
  }
}
