import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  @Input()
  subject: string;
  @Input()
  confirmationText: string;
  @Input()
  shouldTypeConfirm: boolean;

  /**
   * Specify whether the text 'Delete' or 'Remove' should be shown
   */
  @Input()
  langKey: 'delete' | 'remove' = 'delete';

  @Input()
  onCancel: () => void;
  @Input()
  onConfirm: () => void;

  confirmationForm?: FormGroup;

  constructor(
    protected ref: NbDialogRef<DeleteDialogComponent>,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.confirmationForm = this.formBuilder.group({
      confirmationTextbox: [''],
    });
  }

  /**
   * Check that confirmationText and user entered Text are the same
   */
  isTextCorrect(): boolean {
    if (!this.shouldTypeConfirm) {
      return true;
    }
    return this.confirmationForm.get('confirmationTextbox')?.value === this.confirmationText;
  }

  confirm(): void {
    this.onConfirm();
    this.ref.close();
  }

  dismiss() {
    if (this.onCancel) {
      this.onCancel();
    }
    this.ref.close();
  }

}
