import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, shareReplay, debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  protected layoutSize$ = new Subject();
  protected layoutSizeChange$ = this.layoutSize$.pipe(
    shareReplay({ refCount: true })
  );

  // Create an observable to prevent subscribers to modify the Subject directly
  private shouldShowLoginButtons: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly shouldShowLoginButtons$: Observable<boolean> = this.shouldShowLoginButtons.asObservable();

  changeLayoutSize() {
    this.layoutSize$.next(undefined); // TODO: Check if undefined is fine here
  }

  onChangeLayoutSize(): Observable<any> {
    return this.layoutSizeChange$.pipe(delay(1));
  }

  onSafeChangeLayoutSize(): Observable<any> {
    return this.layoutSizeChange$.pipe(debounceTime(350));
  }

  updateShowLoginButtons(shouldShow: boolean) {
    this.shouldShowLoginButtons.next(shouldShow);
  }
}
