import { User } from '../user/user.model';
import { Team } from '../team/team.model';
import { Course } from '../course/course.model';
import { ProgramManager } from '../program/program.model';

export enum CourseAccessLevel {
  INSTRUCTOR = 'instructor',
  COACH = 'coach',
  USER = 'user',
}

export enum MetadataType {
  MIRO = 'miro',
  GOOGLE_DRIVE = 'google_drive',
  CUSTOM_LINK = 'custom_link',
  GOOGLE_CALENDAR = 'google_calendar',
  SCHEDULING = 'scheduling',
  CONFERENCING = 'conferencing',
  REVIEWEE = 'reviewee',
  PLACEHOLDER = 'placeholder',
}

export interface Metadata {
  id?: string;
  name: string;
  type: MetadataType;
  value?: string;
  openInNewTab?: boolean;
  includeInToolbar?: boolean;
  additionalData?: {
    publishEvents?: boolean;
  };
}

export interface CourseMember {
  id?: string;
  team: Team;
  user: User;
  course: Course;
  programManager?: ProgramManager;
  accessLevel: CourseAccessLevel;
  completedTasks: Task[] | string[];
  archived: boolean;
  readonly name?: string; // virtual field
  readonly profilePicturePreviewUrl?: string; // virtual field
  readonly s3ProfilePictureKey?: string; // Virtual field

  // Map from metadata id to value
  // metadata id as key
  // value as value
  metadataValues?: any;
}
