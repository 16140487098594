<nb-card *ngIf="content">
  <nb-card-body>
    <div class="ql-container ql-snow ngx-quill-view-html" style="border: none">
      <div class="ql-editor" [innerHTML]="content"></div>
    </div>
  </nb-card-body>
</nb-card>

<nb-alert *ngIf="!content" translate="tenantLinks.linkEmpty">
</nb-alert>
