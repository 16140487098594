import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Team } from './team.model';
import { catchError, map } from 'rxjs/operators';
import { TEAM_IDENTIFIER, USER_IDENTIFIER } from '../shared/constants';
import { Router } from '@angular/router';
import { RoleProvider } from '../auth/role.provider';
import { ManagementService } from '../core/services/management.service';
import { ToastrService } from '../core/services/toastr.service';
import { RefreshService } from '../auth/refresh.service';
import { UserService } from '../user/user.service';
import { FileDatabase } from '../file/file.model';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(
    private http: HttpClient,
    private roleProvider: RoleProvider,
    private managementService: ManagementService,
    private router: Router,
    private refreshService: RefreshService,
    private toastrService: ToastrService,
    private userService: UserService,
    private fileService: FileService,
  ) {}

  // This static method is required to break dependency circles
  static getTeamIdentifier(): string | undefined {
    return localStorage.getItem(TEAM_IDENTIFIER);
  }

  static isTeamMode(): boolean {
    return !!localStorage.getItem(TEAM_IDENTIFIER);
  }

  public getAllTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(`/api/team`);
  }

  public getTeam(id: string, forEdit = false): Observable<Team> {
    return this.http.get<Team>(`/api/team/${id}?forEdit=${forEdit}`);
  }

  /**
   * Create a task. The id is not required but will be created on the server.
   * The user MUST BE ADMINISTRATOR OR INSTRUCTOR to do this.
   *
   * @param team
   */
  public createTeam(team: Team): Observable<Team> {
    return this.http.post<Team>(`/api/team`, team);
  }

  /**
   * Update a task.
   * The user MUST BE ADMINISTRATOR to do this.
   *
   * @param team
   * @param teamId
   */
  public updateTeam(team: Team, teamId: string): Observable<Team> {
    return this.http.put<Team>(`/api/team/${teamId}`, team);
  }

  public updateTeamTenantDetails(businessTags: string[], status: string[], teamId: string): Observable<Team> {
    return this.http.put<Team>(`/api/team/${teamId}/tenantData`, {
      businessTags,
      status,
    });
  }

  getAvailableTeamBusinessTagsAndStatus(): Observable<{businessTags: string[], status: string[]}> {
    return this.http.get<{businessTags: string[], status: string[]}>(`/api/team/availableTeamBusinessTagsAndStatus`);
  }

  /**
   * Delete a team.
   * The user MUST BE SUPER ADMINISTRATOR to do this.
   *
   * @param id
   */
  public deleteTeam(id: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(
      `/api/team/${id}`
    );
  }

  /**
   * Remove a team from the current tenant. The performing user must be administrator.
   *
   * @param id
   */
  removeTeamFromTenant(id: string): Observable<void> {
    return this.http.delete<void>(`/api/team/removeFromTenant/${id}`, {});
  }

  public addUserToTeam(teamId: string, userId: string): Observable<Team> {
    return this.http.post<Team>(
      `/api/team/${teamId}/members?user=${userId}`,
      {}
    );
  }

  public inviteUserToTeam(teamId: string, userId: string): Observable<Team> {
    return this.http.post<Team>(
      `/api/team/${teamId}/members/invite?user=${userId}`,
      {}
    );
  }

  public acceptInvitation(invitationId: string): Observable<Team> {
    return this.http.post<Team>(
      `/api/team/invitation/accept/${invitationId}`,
      {}
    );
  }

  public declineInvitation(invitationId: string): Observable<Team> {
    return this.http.post<Team>(
      `/api/team/invitation/decline/${invitationId}`,
      {}
    );
  }

  public cancelInvitation(invitationId: string): Observable<Team> {
    return this.http.post<Team>(
      `/api/team/invitation/cancel/${invitationId}`,
      {}
    );
  }

  public removeUserFromTeam(teamId: string, userId: string): Observable<Team> {
    return this.http.delete<Team>(
      `/api/team/${teamId}/members?user=${userId}`
    );
  }

  public getSelfEnrollmentTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(
      `/api/team/selfEnrollment`
    );
  }

  public enroll(
    id: string,
    enrollmentKey?: string
  ): Observable<{ success: boolean; message?: string }> {
    return this.http
      .post<{ success: boolean; message?: string }>(
        `/api/team/${id}/enroll`,
        { enrollmentKey }
      )
      .pipe(
        map(() => ({ success: true })),
        catchError((err) => of({ success: false, message: err.error.message }))
      );
  }

  public populatePictureLinkIfAvailable(team: Team): void {
    if (!team.picture || team.pictureLink) {
      return;
    }

    this.fileService.getS3Url(team.picture.id, FileDatabase.MANAGEMENT, undefined, 400).subscribe(link => team.pictureLink = link);
  }

  /**
   * Get all teams that have a name contains a substring.
   *
   * @param substring
   */
  getMatchingTeams(substring: string): Observable<Team[]> {
    return this.http.get<Team[]>(
      `/api/team/matching?substring=${substring}`
    );
  }

  activateTeamForTenant(teamId: string): Observable<any> {
    return this.http.post<void>(
      `/api/team/${teamId}/activateForTenant`,
      {}
    );
  }

  isTeamMode(): boolean {
    return !!localStorage.getItem(TEAM_IDENTIFIER);
  }

  getTeamIdentifier(): string | undefined {
    return localStorage.getItem(TEAM_IDENTIFIER);
  }

  setTeamIdentifier(teamId: string, showToast = true): void {
    const teamIdentifierBefore = this.getTeamIdentifier();
    localStorage.setItem(TEAM_IDENTIFIER, teamId);
    this.userService.reloadUserInfo();

    if (teamIdentifierBefore !== teamId && showToast) {
      this.toastrService.primaryToast('alerts.profileSwitched');
    }
  }

  removeTeamIdentifier(showToast = true, reloadUserInfo = true): void {
    const teamIdentifierBefore = this.getTeamIdentifier();
    localStorage.removeItem(TEAM_IDENTIFIER);
    if (reloadUserInfo) {
      this.userService.reloadUserInfo();
    }

    if (teamIdentifierBefore && showToast) {
      this.toastrService.primaryToast('alerts.profileSwitched');
    }
  }

  changeTeamIdentifier(teamIdOrUser: string, showToast = true): void {
    if (teamIdOrUser === USER_IDENTIFIER) {
      this.removeTeamIdentifier(showToast);
    } else {
      this.setTeamIdentifier(teamIdOrUser, showToast);
    }
  }

  canAdminManageTeam(team: Team): Observable<boolean> {
    const tenantId = this.managementService.tenant?.id;

    if (!tenantId) {
      return of(false);
    }

    if (!team.activatedTenants[tenantId]) {
      // Team is not active on this tenant (Should not happen)
      return of(false);
    }

    const tenantIds = Object.keys(team.activatedTenants);
    const otherTenantIds: string[] = tenantIds.filter(i => i !== tenantId);
    if (otherTenantIds.length === 0) {
      // Only active on this instance -> Allowed
      return of(true);
    }

    // Active on other instances as well -> Only allowed if super admin
    return this.roleProvider.isAtLeastSuperAdmin();
  }

  openUserProfile(): void {
    const isTeamMode = TeamService.isTeamMode();

    if (isTeamMode) {
      this.removeTeamIdentifier();
    }
    this.router.navigate(['/user', 'profile']);
  }
}
