import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-page-ribbon',
  templateUrl: './page-ribbon.component.html',
  styleUrls: ['./page-ribbon.component.scss'],
})
export class PageRibbonComponent implements OnInit {
  ribbonEnv?: string;

  ngOnInit(): void {
    const ribbonText = environment.ribbon;

    if (ribbonText) {
      this.ribbonEnv = ribbonText;
    }
  }
}
