<app-page-ribbon></app-page-ribbon>
<nb-layout>
  <nb-layout-header>
    <app-header></app-header>
  </nb-layout-header>

  <nb-layout-column>
    <div class="max-width">
      <div class="container-fluid">
        <app-maintenance-banner></app-maintenance-banner>
        <app-role-updated-banner></app-role-updated-banner>
        <app-build-updated-banner></app-build-updated-banner>
      </div>
      <router-outlet></router-outlet>
    </div>
  </nb-layout-column>

  <nb-layout-footer>
    <app-footer></app-footer>
  </nb-layout-footer>
</nb-layout>
