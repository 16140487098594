import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { NebularModule } from '../../nebular.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SaveDiscardDialogComponent } from './save-discard-dialog/save-discard-dialog.component';

@NgModule({
  declarations: [
    DeleteDialogComponent,
    ConfirmDialogComponent,
    SaveDiscardDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NebularModule,
    ReactiveFormsModule,
    TranslateModule,
  ]
})
export class DialogModule { }
