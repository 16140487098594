import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AUTH_STRATEGY } from '../../shared/constants';
import { AuthService } from '../auth.service';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { ManagementService } from '../../core/services/management.service';
import { TeamService } from '../../team/team.service';
import { Course, CourseEnrollmentType } from '../../course/course.model';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user.model';
import { trimSpaces } from '../../core/services/utils';
import { TeamActivateDialogComponent } from '../../team/team-activate-dialog/team-activate-dialog.component';
import { DialogService } from '../../layout/dialog/dialog.service';

@Component({
  selector: 'app-team-invite-accept',
  templateUrl: './invite-accept.component.html',
  styleUrls: ['./invite-accept.component.scss']
})
export class InviteAcceptComponent implements OnInit {

  invitationCode: string;

  showSpinner = true;
  user: User;
  userCourseIds: string[];
  teamCourseIds: {[key: string]: string[]} = {};
  courseDetails?: {id: string; name: string; enrollmentType: CourseEnrollmentType; chapterId?: string; taskId?: string};
  teamName = '';

  createTeamLoading = false;

  eCourseEnrollmentType = CourseEnrollmentType;

  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private authService: AuthService,
    private managementService: ManagementService,
    private dialogService: DialogService,
    private nbAuthService: NbAuthService,
    private tokenService: NbTokenService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.invitationCode = this.route.snapshot.paramMap.get('invitationCode');
    if (!this.invitationCode) {
      return;
    }

    const prefilledEmail = this.route.snapshot.queryParamMap.get('prefilledEmail');

    this.nbAuthService.isAuthenticatedOrRefresh().subscribe(
      (authenticated) => {
        if (authenticated) {

          this.userService.getUserInfo().subscribe(user => {
            this.user = user;
            this.userCourseIds = user.courseMembers.map(cm => cm.course.id);
            for (const team of user.teams) {
              this.teamCourseIds[team.id] = team.courseMembers.map(cm => cm.course as unknown as string);
            }
          });

          this.authService.getInvitationCodeDetails(this.invitationCode).subscribe(({courseDetails}) => {
            if (courseDetails) {
              this.courseDetails = courseDetails;
              if (courseDetails.enrollmentType === CourseEnrollmentType.USER) {
                this.joinCourseAsUser();
              } else {
                this.showSpinner = false;
              }

            } else {
              this.authService.useInvitationCodeAsLoggedInUser(this.invitationCode).subscribe(res => {
                if (res.team) {
                  this.teamService.setTeamIdentifier(res.team.id);
                  this.router.navigate(['/dashboard']);
                }
                if (res.applicant) {
                  this.router.navigate(['/application', res.applicant.applicationProcess, 'apply']).then(() => {
                  });
                }
              }, () => {
                this.router.navigate(['/dashboard']);
              });
            }
          });
        } else {
          this.nbAuthService.logout(AUTH_STRATEGY);
          this.tokenService.clear();

          if (prefilledEmail) {
            // prefilled email -> Redirect to register
            const tenantId = this.managementService.tenant.id;
            location.href = `${environment.ssoUrl}/register/${tenantId}?invitationCode=${this.invitationCode}&prefilledEmail=${prefilledEmail}`;
          } else {
            // No prefilled email -> Redirect to login
            this.nbAuthService.authenticate(AUTH_STRATEGY, {
              // Add current pathname to state -> Can be extracted by callback component
              state: window.location.pathname,
            }).subscribe(() => {
              // We have to subscribe to make the call
            });
          }
        }
      });
  }


  joinCourseAsUser(): void {
    this.teamService.removeTeamIdentifier();
    this.authService.useInvitationCodeAsLoggedInUser(this.invitationCode).subscribe(({course}) => {
      this.navigateToCourseInviteTarget(course);
    });
  }

  joinCourseAsTeam(teamId: string): void {
    const team = this.user.teams.find(t => t.id === teamId);

    const isAlreadyActivatedForTenant = team.activatedTenants[this.managementService.tenant.id];

    if (isAlreadyActivatedForTenant) {
      this.teamService.setTeamIdentifier(teamId);
      this.authService.useInvitationCodeAsLoggedInUser(this.invitationCode).subscribe(({course}) => {
        this.navigateToCourseInviteTarget(course);
      });
      return;
    }

    this.dialogService.open(TeamActivateDialogComponent, {
      context: {
        team,
        activated: () => {
          this.teamService.setTeamIdentifier(teamId);
          this.authService.useInvitationCodeAsLoggedInUser(this.invitationCode).subscribe(({course}) => {
            this.navigateToCourseInviteTarget(course);
          });
        },
      }
    });
  }

  isTeamNameValid(): boolean {
    return trimSpaces(this.teamName) !== '';
  }

  createTeam(): void {
    this.createTeamLoading = true;
    this.teamService.createTeam({
      name: trimSpaces(this.teamName),
      members: [],
    }).subscribe(team => {
      this.userService.getUserInfo().subscribe((user) => {
        this.user = user;
        this.joinCourseAsTeam(team.id);
      });
    });
  }

  navigateToCourseInviteTarget(course: Course): void {
    if (this.courseDetails.taskId && this.courseDetails.chapterId) {
      this.router.navigate(['/course', course.shortName, 'chapter', this.courseDetails.chapterId, 'task', this.courseDetails.taskId]);
      return;
    }

    if (this.courseDetails.chapterId) {
      this.router.navigate(['/course', course.shortName], {fragment: this.courseDetails.chapterId});
      return;
    }

    this.router.navigate(['/course', course.shortName]);
  }
}
