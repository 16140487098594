import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, of } from 'rxjs';
import { NbAuthOAuth2JWTToken, NbAuthOAuth2Token, NbAuthService, NbTokenService } from '@nebular/auth';
import { UserService } from '../user/user.service';
import { first, map, switchMap } from 'rxjs/operators';
import { RoleProvider } from './role.provider';
import { InvitedUser } from './invitedUser.model';
import { Session } from './session.model';
import { Team } from '../team/team.model';
import { Applicant } from '../application/application.model';
import { Course, CourseEnrollmentType } from '../course/course.model';
import { RefreshService } from './refresh.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private nbAuthService: NbAuthService,
    private refreshService: RefreshService,
    private nbTokenService: NbTokenService,
    private roleProvider: RoleProvider,
  ) {}

  activateAccount(
    userId: string,
    token: string
  ): Observable<{ success?: boolean; jwToken?: string; message?: string }> {
    return this.http.post<{ success?: boolean; jwToken?: string; message?: string }>(
      `/api/auth/activate?user=${userId}`,
      { token }
    );
  }

  changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<{ success: boolean; message?: string }> {
    return this.http.post<{ success: boolean; message?: string }>(
      `/api/auth/change-password`,
      { oldPassword, newPassword }
    );
  }

  useInvitationCodeAsLoggedInUser(invitationCode: string): Observable<{ team?: Team; applicant?: Applicant; course?: Course }> {
    return this.http.post<{ team?: Team; applicant?: Applicant }>(
      `/api/auth/useInvitationCode?invitationCode=${invitationCode}`,
      {}
    );
  }

  getInvitationCodeDetails(invitationCode: string): Observable<{ courseDetails?: {id: string; name: string; enrollmentType: CourseEnrollmentType; chapterId?: string; taskId?: string} }> {
    return this.http.get<{ courseDetails?: {id: string; name: string; enrollmentType: CourseEnrollmentType; chapterId?: string; taskId?: string} }>(
      `/api/auth/invitationCodeDetails?invitationCode=${invitationCode}`,
    );
  }

  inviteUser(teamId: string, email: string): Observable<{success: boolean; invitedUser: InvitedUser}> {
    return this.http.post<{success: boolean; invitedUser: InvitedUser}>(
      `/api/auth/invitation/user?teamId=${teamId}&email=${encodeURIComponent(email)}`,
      {}
    );
  }

  createInvitationLink(teamId: string): Observable<{invitedUser: InvitedUser; registerUrl: string}> {
    return this.http.post<{invitedUser: InvitedUser; registerUrl: string}>(
      `/api/auth/invitation/link?teamId=${teamId}`,
      {}
    );
  }

  getPendingInvitedUsers(teamId: string): Observable<InvitedUser[]> {
    return this.http.get<InvitedUser[]>(
      `/api/auth/invitation/user?teamId=${teamId}`,
    );
  }

  revokeInvitedUser(invitedUserId: string): Observable<void> {
    return this.http.delete<void>(
      `/api/auth/invitation/${invitedUserId}`,
    );
  }

  isAtLeastInstructorInCourse(courseShortName: string): Observable<boolean> {
    // Check if user is admin or instructor in course and combine results for return value
    const admin$ = this.roleProvider.isAtLeastAdmin().pipe(first());
    const instructorShortNames$ = this.userService.getCoursesInstructorShortNames().pipe(first());

    return combineLatest(
      [admin$, instructorShortNames$], (isAdmin, instructorShortNames) => ({isAdmin, instructorShortNames}))
      .pipe(
        map(pair => pair.isAdmin || pair.instructorShortNames.includes(courseShortName)
        ));
  }

  isAtLeastCoachInCourse(courseShortName: string): Observable<boolean> {
    // Check if user is at least instructor or coach in course and combine results for return value
    const isAtLeastInstructorInCoach$ = this.isAtLeastInstructorInCourse(courseShortName).pipe(first());
    const coachShortNames$ = this.userService.getCoursesCoachShortNames().pipe(first());

    return combineLatest(
      [isAtLeastInstructorInCoach$, coachShortNames$], (isAtLeastInstructor, coachShortNames) => ({isAtLeastInstructor, coachShortNames}))
      .pipe(
        map(pair => pair.isAtLeastInstructor || pair.coachShortNames.includes(courseShortName)
        ));
  }


  isAtLeastSkillManager(skillId: string): Observable<boolean> {
    // Check if user is admin or instructor in course and combine results for return value
    const admin$ = this.roleProvider.isAtLeastAdmin().pipe(first());
    const skillsManagerIds$ = this.userService.getSkillsManagerIds().pipe(first());

    return combineLatest(
      [admin$, skillsManagerIds$], (isAdmin, skillsManagerIds) => ({isAdmin, skillsManagerIds}))
      .pipe(
        map(pair => pair.isAdmin || pair.skillsManagerIds.includes(skillId)
        ));
  }

  /**
   * Returns true if valid auth token is present in the token storage.
   * If not, calls the strategy refreshToken, and returns isAuthenticated() if success, false otherwise
   *
   * Will not perform multiple requests to refresh the token but will only do it once (to ensure that the token is only used once)
   *
   */
  isAuthenticatedOrRefresh(): Observable<boolean> {
    return this.nbAuthService.getToken()
      .pipe(
        switchMap((token: NbAuthOAuth2Token) => {
          if (token.getValue()) {
            // Tokens that are valid for less than 23 hours should be refreshed
            const maximumTokenExpiryDateBeforeRenewal = new Date(new Date().getTime() + 23 * 60 * 60 * 1000);
            const refreshRequired = !token.isValid() || token.getTokenExpDate().getTime() < maximumTokenExpiryDateBeforeRenewal.getTime();

            if (refreshRequired) {
              return this.refreshService.refreshToken(token);
            } else {
              return of(token.isValid());
            }
          } else {
            return of(token.isValid());
          }
        }));
  }

  /**
   * Get all current sessions for the logged-in user.
   */
  getCurrentSessions(): Observable<Session[]> {
    return this.http.get<Session[]>(`/api/auth/sessions`);
  }

  /**
   * Revoke a single session of the logged-in user.
   *
   * @param sessionId
   */
  revokeSession(sessionId: string): Observable<void> {
    return this.http.delete<void>(`/api/auth/sessions/${sessionId}`);
  }

  /**
   * Revoke all sessions of the logged-in user.
   * This includes the current session.
   */
  revokeAllSessions(): Observable<void> {
    return this.http.delete<void>(`/api/auth/sessions`);
  }
}
