import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurePipe } from './secure/secure.pipe';
import { SafePipe } from './safe/safe.pipe';
import { RelativeTimePipe } from './relative-time/relative-time.pipe';
import { FloorPipe } from './floor/floor.pipe';
import { ServerDateToDatePipe } from './server-date-to-date.pipe';
import { EventStartDateFromFactPipe } from './event-start-date-from-fact/event-start-date-from-fact.pipe';
import { EventEndDateFromFactPipe } from './event-end-date-from-fact/event-end-date-from-fact.pipe';
import { LiveSkillPipe } from './live-skill/live-skill.pipe';
import { EventPipe } from './event/event.pipe';
import { MemberDetailsPipe } from './member-details/member-details.pipe';
import { GroupPipe } from '../form/question/pipes/group/group.pipe';
import { SelfpacedSkillPipe } from './selfpaced-skill/selfpaced-skill.pipe';
import { SortPipe } from './sort/sort.pipe';

@NgModule({
    declarations: [
      SecurePipe,
      SafePipe,
      RelativeTimePipe,
      FloorPipe,
      ServerDateToDatePipe,
      EventStartDateFromFactPipe,
      EventEndDateFromFactPipe,
      LiveSkillPipe,
      EventPipe,
      MemberDetailsPipe,
      GroupPipe,
      SelfpacedSkillPipe,
      SortPipe,
    ],
    imports: [CommonModule],
    exports: [
        FloorPipe,
        SecurePipe,
        SafePipe,
        RelativeTimePipe,
        FloorPipe,
        ServerDateToDatePipe,
        EventStartDateFromFactPipe,
        EventEndDateFromFactPipe,
        LiveSkillPipe,
        EventPipe,
        MemberDetailsPipe,
        GroupPipe,
        SortPipe,
    ],
})
export class PipeModule {}
