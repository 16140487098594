export interface File {
  id: string;
  name?: string;
  type?: string;
  readonly fileDatabase?: FileDatabase;
  // TODO: Add attributes
}

export enum FileDatabase {
  TENANT = 'tenant',
  SKILL = 'skill',
  MANAGEMENT = 'management',
}
