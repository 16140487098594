import { Component, HostBinding, Input } from '@angular/core';
import { NbComponentOrCustomStatus, NbComponentSize } from '@nebular/theme';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

  /**
   * Text to display
   * @type string
   */
  @Input() text: string = '';

  @Input() size: NbComponentSize = 'tiny';

  /**
   * Badge status (adds specific styles):
   * 'basic', 'primary', 'info', 'success', 'warning', 'danger', 'control'
   */
  @Input() status: NbComponentOrCustomStatus = 'basic';

  @HostBinding('class.badge')
  get badge(): boolean {
    return true;
  }

  @HostBinding('class.badge-primary')
  get primary(): boolean {
    return this.status === 'primary';
  }

  @HostBinding('class.badge-success')
  get success(): boolean {
    return this.status === 'success';
  }

  @HostBinding('class.badge-info')
  get info(): boolean {
    return this.status === 'info';
  }

  @HostBinding('class.badge-warning')
  get warning(): boolean {
    return this.status === 'warning';
  }

  @HostBinding('class.badge-danger')
  get danger(): boolean {
    return this.status === 'danger';
  }

  @HostBinding('class.badge-basic')
  get basic(): boolean {
    return this.status === 'basic';
  }

  @HostBinding('class.badge-control')
  get control(): boolean {
    return this.status === 'control';
  }

  @HostBinding('class.size-tiny')
  get tiny(): boolean {
    return this.size === 'tiny';
  }

  @HostBinding('class.size-small')
  get small(): boolean {
    return this.size === 'small';
  }

  @HostBinding('class.size-medium')
  get medium(): boolean {
    return this.size === 'medium';
  }

  @HostBinding('class.size-large')
  get large(): boolean {
    return this.size === 'large';
  }

  @HostBinding('class.size-giant')
  get giant(): boolean {
    return this.size === 'giant';
  }


}
