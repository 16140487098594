import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { AdminGuard } from './core/guards/admin.guard';
import { UserGuard } from './core/guards/user.guard';
import { TeamGuard } from './core/guards/team.guard';
import { FeatureGuard } from './core/guards/feature.guard';
import { InstructorGuard } from './core/guards/instructor.guard';
import { SuperAdminGuard } from './core/guards/super-admin.guard';
import { ApplicationAuthGuard } from './core/guards/application-auth.guard';
import { TenantMigrationConfirmComponent } from './tenant/tenant-migration-confirm/tenant-migration-confirm.component';
import { CustomTenantPageComponent } from './layout/custom-tenant-page/custom-tenant-page.component';
import { TenantLinkType } from './tenant/tenant.model';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0],
  useHash: false,
  onSameUrlNavigation: 'reload',
};

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard, UserGuard],
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'course',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./course/course.module').then((m) => m.CourseModule),
  },
  {
    path: 'chapter',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./chapter/chapter.module').then((m) => m.ChapterModule),
  },
  {
    path: 'administration',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'tenant',
    canActivate: [AuthGuard, SuperAdminGuard],
    loadChildren: () => import('./tenant/tenant.module').then((m) => m.TenantModule)
  },
  {
    path: 'application',
    canActivate: [FeatureGuard],
    loadChildren: () => import('./application/application.module').then((m) => m.ApplicationModule),
    data: {
      feature: 'application',
    },
  },
  {
    path: 'program',
    canActivate: [AuthGuard, InstructorGuard],
    loadChildren: () => import('./program/program.module').then((m) => m.ProgramModule),
  },
   {
    path: 'file',
    canActivate: [AuthGuard],
    loadChildren: () => import('./file/file.module').then((m) => m.FileModule),
  },
  {
    path: 'skill',
    canActivate: [FeatureGuard],
    loadChildren: () => import('./skill/skill.module').then((m) => m.SkillModule),
    data: {
      feature: 'skills',
    },
  },
  {
    path: 'person',
    loadChildren: () => import('./person/person.module').then((m) => m.PersonModule),
  },
  {
    path: 'community',
    loadChildren: () => import('./community/community.module').then((m) => m.CommunityModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: 'email',
    loadChildren: () => import('./email/email.module').then((m) => m.EmailModule),
  },
  {
    path: 'migration',
    component: TenantMigrationConfirmComponent,
  },
  {
    path: 'privacy',
    component: CustomTenantPageComponent,
    data: {
      linkType: TenantLinkType.PRIVACY,
    }
  },
  {
    path: 'terms',
    component: CustomTenantPageComponent,
    data: {
      linkType: TenantLinkType.TERMS,
    }
  },
  {
    path: 'legal',
    component: CustomTenantPageComponent,
    data: {
      linkType: TenantLinkType.LEGAL,
    }
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [AuthGuard, AdminGuard, UserGuard, TeamGuard, FeatureGuard, InstructorGuard, SuperAdminGuard, ApplicationAuthGuard],
})
export class AppRouting {
}
