import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  LiveSkillInstance,
  SelfPacedSkillInstance,
  Skill,
  SkillAccessLevel,
  SkillContentType,
  SkillInstance,
  SkillInstanceMember,
  SkillInstanceType,
  SkillTaskType,
} from './skill.model';
import { Task } from '../task/task.model';
import { User } from '../user/user.model';
import { RoleProvider } from '../auth/role.provider';
import { ManagementService } from '../core/services/management.service';
import { UserService } from '../user/user.service';
import { Course } from '../course/course.model';
import { Chapter } from '../chapter/chapter.model';
import { EmailCampaign } from '../email/email.model';

@Injectable({
  providedIn: 'root'
})
export class SkillService {
  constructor(
    private http: HttpClient,
    private roleProvider: RoleProvider,
    private managementService: ManagementService,
    private userService: UserService) {
  }

  // Calls without authentication

  public getPublicSkills(): Observable<Skill[]> {
    return this.http.get<Skill[]>(`/api/skill/public`);
  }

  public getPublicSkillDetails(skillId: string): Observable<Skill> {
    return this.http.get<Skill>(`/api/skill/${skillId}/public`);
  }

  // Calls with authentication

  public getSkills(): Observable<{ editableSkills: Skill[]; publicSkills: Skill[] }> {
    return this.http.get<{ editableSkills: Skill[]; publicSkills: Skill[] }>(`/api/skill`);
  }

  public getEditableSkills(): Observable<Skill[]> {
    return this.http.get<Skill[]>(`/api/skill/editable`);
  }

  public getSkill(skillId: string): Observable<Skill> {
    return this.http.get<Skill>(`/api/skill/${skillId}`);
  }

  public getSkillIdBySkillInstanceId(skillInstanceId: string): Observable<string> {
    return this.http.get<string>(`/api/skill/skillIdBySkillInstanceId/${skillInstanceId}`);
  }

  public getSkillManagers(skillId: string): Observable<User[]> {
    return this.http.get<User[]>(`/api/skill/${skillId}/managers`);
  }

  public addSkillManager(skillId: string, userId: string): Observable<void> {
    return this.http.post<void>(`/api/skill/${skillId}/managers/${userId}`, {});
  }

  public removeSkillManager(skillId: string, userId: string): Observable<void> {
    return this.http.delete<void>(`/api/skill/${skillId}/managers/${userId}`);
  }

  public exportSkillRegistrations(skillId: string): Observable<Blob> {
    return this.http.get(`/api/skill/${skillId}/registrations/export`, {responseType: 'blob'});
  }

  public exportRegistrationsForAllSkillsInTenant(startDate: Date, endDate: Date): Observable<void> {
    return this.http.get<void>(`/api/skill/registrations/export`, {params: {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    }});
  }

  /**
   * Create a skill. The id is not required but will be created on the server.
   * The user MUST BE INSTRUCTOR to do this.
   *
   * @param skill
   */
  public createSkill(skill: Skill): Observable<Skill> {
    return this.http.post<Skill>(`/api/skill`, skill);
  }

  /**
   * Update a skill.
   * The user MUST BE AN OWNER of the skill to do this.
   *
   * @param skill
   */
  public updateSkill(skill: Skill, skillId: string): Observable<Skill> {
    return this.http.put<Skill>(`/api/skill/${skillId}`, skill);
  }

  /**
   * Delete a skill.
   * The user MUST BE AN OWNER of the skill to do this.
   *
   * @param skill
   */
  public deleteSkill(skillId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`/api/skill/${skillId}`);
  }

  public getTagsForSkills(substring: string): Observable<string[]> {
    return of(['Workshop', 'Team', 'Business Model']);
    return this.http.get<string[]>(`/api/skill/tags?substring=${substring}`);
  }

  public getSkillInstanceByIncludeCode(includeCode: string): Observable<SkillInstance> {
    return this.http.post<SkillInstance>(`/api/skill/byIncludeCode`, {includeCode});
  }

  public getSkillInstance(skillId: string, skillInstanceId: string, withCode = false, withSubmissions = false): Observable<SkillInstance> {
    return this.http.get<SkillInstance>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}`, {params: {withCode, withSubmissions}});
  }

  public updateSkillInstance(skillId: string, skillInstanceId: string, skillInstance: SkillInstance): Observable<SkillInstance> {
    return this.http.put<SkillInstance>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}`, skillInstance);
  }

  public updateSkillInstanceMetadata(skillId: string, skillInstanceId: string, skillInstance: SkillInstance): Observable<SkillInstance> {
    return this.http.put<SkillInstance>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/metadata`, skillInstance);
  }

  public duplicateSkillInstance(skillId: string, skillInstanceId: string): Observable<SkillInstance> {
    return this.http.post<SkillInstance>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/duplicate`, {});
  }

  public deleteSkillInstance(skillId: string, skillInstanceId: string): Observable<void> {
    return this.http.delete<void>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}`);
  }

  public getSkillInstanceMembers(skillId: string, skillInstanceId: string, accessLevel?: SkillAccessLevel): Observable<SkillInstanceMember[]> {
    const params: any = {};

    if (accessLevel) {
      params.accessLevel = accessLevel;
    }

    return this.http.get<SkillInstanceMember[]>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/members`, {params});
  }

  public getCurrentSkillInstanceMember(skillId: string, skillInstanceId: string): Observable<SkillInstanceMember | undefined> {
    return this.http.get<SkillInstanceMember | undefined>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/members/current`);
  }

  public getSkillInstanceMember(skillId: string, skillInstanceId: string, skillInstanceMemberId: string): Observable<SkillInstanceMember> {
    return this.http.get<SkillInstanceMember>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/members/${skillInstanceMemberId}`);
  }

  public setSkillInstanceMember(skillId: string, skillInstanceId: string, accessLevel: SkillAccessLevel, tenantId: string, userId: string | undefined, teamId: string | undefined): Observable<SkillInstanceMember> {
    return this.http.post<SkillInstanceMember>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/members`, {}, {
      params: {
        accessLevel,
        tenantId,
        userId,
        teamId
      }
    });
  }

  public updateSkillInstanceMember(skillId: string, skillInstanceId: string, skillInstanceMemberId: string, skillInstanceMember: SkillInstanceMember): Observable<SkillInstanceMember> {
    return this.http.put<SkillInstanceMember>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/members/${skillInstanceMemberId}`, skillInstanceMember);
  }

  public deleteSkillInstanceMember(skillId: string, skillInstanceId: string, skillInstanceMemberId: string): Observable<void> {
    return this.http.delete<void>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/members/${skillInstanceMemberId}`);
  }

  public enrollInSkillInstance(skillId: string, skillInstanceId: string): Observable<SkillInstanceMember> {
    return this.http.post<SkillInstanceMember>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/enroll`, {});
  }

  getCampaigns(skillId: string, skillInstanceId: string): Observable<EmailCampaign[]> {
    return this.http.get<EmailCampaign[]>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/emailCampaigns`);
  }

  sendEmailForSkillInstanceMembers(skillId: string, skillInstanceId: string, emailContent: string, subject: string): Observable<void> {
    return this.http.post<void>(`/api/skill/${skillId}/skillInstance/${skillInstanceId}/sendEmail`, {
      emailContent,
      subject
    });
  }

  /**
   * Get all task ids that the current user has already submitted.
   * This also includes tasks that do not have a form but which the user viewed.
   */
  public getSubmittedTaskIdsForSkillInstance(
    skillId: string,
    skillInstanceId: string,
  ): Observable<{ taskId: string; valid: boolean; changesRequested: boolean }[]> {
    return this.http.get<{ taskId: string; valid: boolean; changesRequested: boolean }[]>(
      `/api/skill/${skillId}/skillInstance/${skillInstanceId}/submittedTasks`
    );
  }

  public getOwnSkillInstanceMembers(onlyCurrentTenant = true, includeCourseMemberSkillInstances = false): Observable<SkillInstanceMember[]> {
    return this.http.get<SkillInstanceMember[]>(`/api/skill/ownSkillInstanceMembers`, {
      params: {
        onlyCurrentTenant,
        includeCourseMemberSkillInstances
      }
    });
  }

  getCourseAndChapterForOwnSkillInstanceMember(skillInstanceMemberId: string): Observable<{ course: Course; chapter: Chapter }> {
    return this.http.get<{ course: Course; chapter: Chapter }>(`/api/skill/skillInstanceMemberDetails/${skillInstanceMemberId}`);
  }

  public getTasksFromSkillInstance(skillInstance: SkillInstance): Task[] {
    if (!skillInstance?.type) {
      throw new Error('getTasksFromSkillInstance received skill instance with no type');
    }
    const tasks = [skillInstance.applicationTask];

    switch (skillInstance.type) {
      case SkillInstanceType.LIVE_SKILL_INSTANCE: {
        const liveSkillInstance = skillInstance as LiveSkillInstance;
        tasks.push(liveSkillInstance.preparationTask, liveSkillInstance.event, liveSkillInstance.followUpTask);
        break;
      }
      case SkillInstanceType.SELF_PACED_SKILL_INSTANCE: {
        const selfPacedSkillInstance = skillInstance as SelfPacedSkillInstance;
        tasks.push(...(selfPacedSkillInstance.customTasks || []));
        tasks.push(selfPacedSkillInstance.assessmentTask);
        break;
      }
      default:
        throw new Error('Unsupported skill instance type in getTasksFromSkillInstance');
    }

    tasks.push(skillInstance.feedbackTask);

    return tasks.filter(t => t !== undefined);
  };

  public getSkillInstanceContent(skillInstance: SkillInstance, skillId: string, forEdit: boolean): SkillContentType[] {
    if (skillInstance.type === SkillInstanceType.LIVE_SKILL_INSTANCE) {
      return this.getLiveSkillInstanceContent(skillInstance as LiveSkillInstance, skillId, forEdit);
    } else if (skillInstance.type === SkillInstanceType.SELF_PACED_SKILL_INSTANCE) {
      return this.getSelfpacedSkillInstanceContent(skillInstance as SelfPacedSkillInstance, skillId, forEdit);
    }

    return [];
  }

  public getLiveSkillInstanceContent(skillInstance: LiveSkillInstance, skillId: string, forEdit: boolean): SkillContentType[] {
    const baseLink = `/skill/${skillId}/instance/${skillInstance.id}/`;

    const content: SkillContentType[] = [
      {
        titleKey: 'Registration', // TODO: Translate this.
        link: `/registration/${skillInstance.registrationProcess ? skillInstance.registrationProcess.id + '/edit' : '/create'}`,
        exists: !!skillInstance.registrationProcess,
        queryParams: {taskType: SkillTaskType.REGISTRATION, skill: true, skillInstanceId: skillInstance.id}
      },
    ];

    // TODO: Deprecate this in the future when it is not used anymore
    if (!!skillInstance.applicationTask) {
      content.push({
        titleKey: 'Application (Deprecated)', // TODO: Translate this.
        title: skillInstance.applicationTask?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.applicationTask?.id, forEdit),
        exists: !!skillInstance.applicationTask,
        queryParams: {taskType: SkillTaskType.APPLICATION}
      });
    };

    content.push(
      {
        titleKey: 'Preparation', // TODO: Translate this
        title: skillInstance.preparationTask?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.preparationTask?.id, forEdit),
        exists: !!skillInstance.preparationTask,
        queryParams: {taskType: SkillTaskType.PREPERATION}
      },
      {
        titleKey: 'Event', // TODO: Translate this
        title: skillInstance.event?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.event?.id, forEdit),
        exists: !!skillInstance.event,
        queryParams: {taskType: SkillTaskType.EVENT}
      },
      {
        titleKey: 'Homework', // TODO: Translate this
        title: skillInstance.followUpTask?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.followUpTask?.id, forEdit),
        exists: !!skillInstance.followUpTask,
        queryParams: {taskType: SkillTaskType.FOLLOWUP}
      },
      {
        titleKey: 'Feedback', // TODO: Translate this
        title: skillInstance.feedbackTask?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.feedbackTask?.id, forEdit),
        exists: !!skillInstance.feedbackTask,
        queryParams: {taskType: SkillTaskType.FEEDBACK}
      });

    return content;
  }

  public getSelfpacedSkillInstanceContent(skillInstance: SelfPacedSkillInstance, skillId: string, forEdit: boolean): SkillContentType[] {
    const baseLink = `/skill/${skillId}/instance/${skillInstance.id}/`;

    const content: SkillContentType[] = [
      {
        titleKey: 'Registration', // TODO: Translate this.
        link: `/registration/${skillInstance.registrationProcess ? skillInstance.registrationProcess.id + '/edit' : '/create'}`,
        exists: !!skillInstance.registrationProcess,
        queryParams: {taskType: SkillTaskType.REGISTRATION, skill: true, skillInstanceId: skillInstance.id}
      },
    ];

    // TODO: Deprecate this in the future when it is not used anymore
    if (!!skillInstance.applicationTask) {
      content.push({
        titleKey: 'Application (Deprecated)', // TODO: Translate this.
        title: skillInstance.applicationTask?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.applicationTask?.id, forEdit),
        exists: !!skillInstance.applicationTask,
        queryParams: {taskType: SkillTaskType.APPLICATION}
      });
    };

    skillInstance.customTasks.forEach(t => {
      content.push({
        titleKey: 'Custom Task', // TODO: Translate this
        title: t.title,
        link: this.getSkillTaskLink(baseLink, t.id, forEdit),
        exists: true,
        queryParams: {taskType: SkillTaskType.CUSTOM}
      },);
    });


    content.push(
      {
        titleKey: 'Custom Task', // TODO: Translate this
        title: undefined,
        link: this.getSkillTaskLink(baseLink, undefined, forEdit),
        exists: false,
        queryParams: {taskType: SkillTaskType.CUSTOM}
      },
      {
        titleKey: 'Assessment', // TODO: Translate this
        title: skillInstance.assessmentTask?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.assessmentTask?.id, forEdit),
        exists: !!skillInstance.assessmentTask,
        queryParams: {taskType: SkillTaskType.ASSESSMENT}
      },
      {
        titleKey: 'Feedback', // TODO: Translate this
        title: skillInstance.feedbackTask?.title,
        link: this.getSkillTaskLink(baseLink, skillInstance.feedbackTask?.id, forEdit),
        exists: !!skillInstance.feedbackTask,
        queryParams: {taskType: SkillTaskType.FEEDBACK}
      });

    return content;
  }

  public getSkillTaskLink(baseLink: string, taskId: string | undefined, editMode = false): string {
    if (editMode) {
      baseLink = baseLink + 'task/';
      return taskId ? baseLink + taskId + '/edit' : baseLink + 'create';
    } else {
      return taskId ? baseLink + 'taskId/' + taskId : undefined;
    }
  };

  public isUserAtLeastSkillManagerOnTenant(skillOriginTenant: string, skillManagerIds: string[]): Observable<boolean> {
    // TODO: Check whether editing on other instance should be allowed
    if (skillOriginTenant !== this.managementService.getTenantId()) {
      return of(false);
    }

    if (skillManagerIds.includes(this.userService.getCurrentUserId())) {
      return of(true);
    }

    return this.roleProvider.isAtLeastAdmin();

  }
}
