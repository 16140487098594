<nb-card accent="danger">
  <nb-card-body>
    <h5 [translate]="'dialog.' + langKey + '.header'" [translateParams]="{subject: subject}"></h5>
    <p [translate]="'dialog.' + langKey + '.warning'"></p>

    <form [formGroup]="confirmationForm" *ngIf="confirmationForm && shouldTypeConfirm">
      <nb-form-field>
        <label
          class="label"
          for="confirmationText"
          [innerHTML]="'dialog.' + langKey + '.confirmationText' | translate: {confirmationText: confirmationText}"
        ></label>
        <input
          [pattern]="confirmationText"
          [placeholder]="confirmationText"
          nbInput id="confirmationText"
          formControlName="confirmationTextbox"
          autofocus
        />
      </nb-form-field>
    </form>

    <div class="full-width d-flex justify-content-end margin-top-2">
      <button nbButton (click)="dismiss()" class="margin-right" [translate]="'dialog.' + langKey + '.cancel'" status="danger" outline></button>
      <button
        nbButton
        (click)="confirm()"
        [disabled]="!isTextCorrect()"
        [translate]="'dialog.' + langKey + '.confirm'"
        status="danger"
      ></button>
    </div>
  </nb-card-body>
</nb-card>
