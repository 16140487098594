import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManagementService } from '../../core/services/management.service';
import { TenantLinkType } from '../../tenant/tenant.model';

@Component({
  selector: 'app-custom-tenant-page',
  templateUrl: './custom-tenant-page.component.html',
  styleUrls: ['./custom-tenant-page.component.scss']
})
export class CustomTenantPageComponent implements OnInit {
  content: string;
  constructor(private route: ActivatedRoute, private managementService: ManagementService) { }

  ngOnInit(): void {
    const linkType = this.route.snapshot.data['linkType'] as TenantLinkType;

    const tenantLinks = this.managementService.getTenantLinks();

    this.content = '';

    switch (linkType) {
      case TenantLinkType.PRIVACY:
        this.content = tenantLinks?.privacyPage;
        break;
      case TenantLinkType.TERMS:
        this.content = tenantLinks?.termsPage;
        break;
      case TenantLinkType.LEGAL:
        this.content = tenantLinks?.legalPage;
    }
  }

}
