export interface Features {
  review: boolean;
  certificate: boolean;
  miroIntegration: boolean;
  slackIntegration: boolean;
  typeformIntegration: boolean;
  releaseConditions: boolean;
  teamCollaboration: boolean;
  application: boolean;
  onboarding: boolean;
  skills: boolean;
  skillMarketplace: boolean;
  quiz: boolean;
  emailCampaigns: boolean;
}

export interface TenantLinks {
  privacyUrl?: string;
  termsUrl?: string;
  legalUrl?: string;
  privacyPage?: string;
  termsPage?: string;
  legalPage?: string;
}

export enum TenantLinkType {
  PRIVACY = 'privacy',
  TERMS = 'terms',
  LEGAL = 'legal',
}

export interface EmailSettings {
  userName: string;
  senderMail: string;
  senderName: string;
  password: string;
  host: string;
}

export interface Tenant {
  id?: string;
  name: string;
  host: string;
  hostAliases: {
    host: string;
    migrationMessage: string;
  }[];
  database: string;
  maxFileUploadSizeInMB: number;
  logoPrefix: string;
  themePrefix: string;
  locale: string;
  features: Features;
  tenantLinks: TenantLinks;
  currentPolicyVersion: number;
  policyVersions: {policyVersion: number, tenantLinks: TenantLinks}[],
  emailSettings?: EmailSettings;
}
