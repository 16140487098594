<div class="container-fluid">
  <nb-card>
    <nb-card-body>
      <div class="row justify-content-center">
        <div class="col align-self-center max-auth-width">
          <h1 id="title" class="title" translate="authPages.logout.title"></h1>
          <p class="sub-title" translate="authPages.logout.subtitle"></p>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
