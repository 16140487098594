import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  NbResetPasswordComponent,
  NbAuthService,
  NB_AUTH_OPTIONS,
} from '@nebular/auth';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends NbResetPasswordComponent {
  constructor(
    private authService: AuthService,
    service: NbAuthService,
    cd: ChangeDetectorRef,
    router: Router,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
  ) {
    super(service, options, cd, router);
  }

  changePass(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.authService
      .changePassword(this.user.oldPassword, this.user.password)
      .subscribe({
        next: (res) => {
          this.submitted = false;
          setTimeout(() => {
            this.router.navigate(['user', 'profile']);
          }, this.redirectDelay);
          if (res.message) {
            this.messages = [res.message];
          }
        },
        error: (err) => {
          this.submitted = false;
          this.errors = [err.error.message];
        }
      });
  }
}
