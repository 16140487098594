import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() subjectKey: string;
  @Input() hintKey: string;
  @Input() translateParams: any;

  @Input() onCancel: () => void;
  @Input() onConfirm?: () => void;

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>,) { }

  confirm(): void {
    this.onConfirm();
    this.ref.close();
  }

  dismiss() {
    if (this.onCancel) {
      this.onCancel();
    }
    this.ref.close();
  }

}
