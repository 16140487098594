import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RoleProvider } from '../../auth/role.provider';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private authService: NbAuthService,
    private router: Router,
    private roleProvider: RoleProvider
  ) {}

  canActivate(): Observable<boolean> {
    return this.roleProvider.isAtLeastAdmin().pipe(
      tap((isAdmin) => {
        if (!isAdmin) {
          this.router.navigate(['dashboard']);
        }
      })
    );
  }
}
