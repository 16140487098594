import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AUTH_STRATEGY } from '../../shared/constants';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationAuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private nbAuthService: NbAuthService,
              private tokenService: NbTokenService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.nbAuthService.logout(AUTH_STRATEGY);
          this.tokenService.clear();
          this.router.navigate(['/application', route.paramMap.get('applicationId'), 'login-required']);
        }
      })
    );
  }
}
