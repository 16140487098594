import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NebularModule } from '../nebular.module';
import { BreadcrumbComponent } from './breadcrumbs/breadcrumb.component';
import { ToolComponent } from './toolbar/tool/tool.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { PipeModule } from '../pipe/pipe.module';
import { ExternalLinkButtonComponent } from './toolbar/external-link-button/external-link-button.component';
import { ShareComponent } from './share/share.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DateConditionPickerComponent } from '../condition/date-condition-picker/date-condition-picker.component';
import { UrlButtonComponent } from './url-button/url-button/url-button.component';
import { TimezoneComponent } from './timezone/timezone.component';
import { ProgressCircleComponent } from './progress-circle/progress-circle.component';
import { AddCalendarButtonComponent } from './toolbar/add-calendar-button/add-calendar-button.component';
import {
  TableComponent,
  TableFilterTemplateDirective,
  TableHeaderTemplateDirective,
  TableRowTemplateDirective
} from './table/table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { AuthModule } from '../auth/auth.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MergeTextComponent } from './merge-properties/merge-text/merge-text.component';
import { MergeTextAreaComponent } from './merge-properties/merge-text-area/merge-text-area.component';
import { MergeUrlComponent } from './merge-properties/merge-url/merge-url.component';
import { MergeSelectComponent } from './merge-properties/merge-select/merge-select.component';

@NgModule({
  imports: [
    NebularModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    PipeModule,
    ClipboardModule,
    NgxPaginationModule,
    NgxMatomoTrackerModule,
    AuthModule,
    InlineSVGModule,
  ],
  declarations: [
    BreadcrumbComponent,
    ToolComponent,
    ToolbarComponent,
    ExternalLinkButtonComponent,
    ShareComponent,
    DateConditionPickerComponent,
    UrlButtonComponent,
    TimezoneComponent,
    ProgressCircleComponent,
    AddCalendarButtonComponent,
    TableComponent,
    TableHeaderTemplateDirective,
    TableRowTemplateDirective,
    TableFilterTemplateDirective,
    MergeTextComponent,
    MergeTextAreaComponent,
    MergeUrlComponent,
    MergeSelectComponent,
  ],
  exports: [
    BreadcrumbComponent,
    ToolComponent,
    ToolbarComponent,
    ShareComponent,
    DateConditionPickerComponent,
    UrlButtonComponent,
    TimezoneComponent,
    ProgressCircleComponent,
    TableComponent,
    TableHeaderTemplateDirective,
    TableRowTemplateDirective,
    TableFilterTemplateDirective,
    MergeTextComponent,
    MergeTextAreaComponent,
    MergeUrlComponent,
    MergeSelectComponent,
  ],
})
export class SharedModule {
}
