export enum StatusCode {
  INVALID_TEAM = 432,
}

export const USER_IDENTIFIER = 'user';
export const TEAM_IDENTIFIER = 'team-identifier';
export const MAINTENANCE_HEADER = 'maintenance';
export const USER_ROLE = 'user-role';

// https://stackoverflow.com/a/201378/3802758
// eslint-disable-next-line max-len
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const AUTH_STRATEGY = 'oauth2';
export const OAUTH_CLIENT_ID = 'cirqus-web-app';
export const OAUTH_SCOPE = 'token id_token role activatedTenants superAdmin openid';

export const activeUserColors = ["#FF0000", "#008000", "#0000FF", "#800080", "#964B00", "#FF66CC", "#808080", "#BFFF00", "#FFE5B4"];

export const DEFAULT_LOCALE = 'en-US';
