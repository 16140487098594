import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, ɵLocaleDataIndex } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';

import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { MatomoConsentMode, NgxMatomoTrackerModule } from '@ngx-matomo/tracker';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRouting } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth-interceptor';
import { HeaderComponent } from './layout/header/header.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { SharedModule } from './shared/shared.module';
import { FooterComponent } from './layout/footer/footer.component';
import { NebularModule } from './nebular.module';
import { NbGlobalLogicalPosition, NbMenuModule, NbThemeModule, NbToastrModule } from '@nebular/theme';
import { NbAuthModule, } from '@nebular/auth';
import { environment } from '../environments/environment';
import { NbRoleProvider } from '@nebular/security';
import { RoleProvider } from './auth/role.provider';
import { PageRibbonComponent } from './layout/page-ribbon/page-ribbon.component';
import { ErrorInterceptor } from './error/error-interceptor';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { MaintenanceBannerComponent } from './layout/maintenance-banner/maintenance-banner.component';
import { ManagementService } from './core/services/management.service';
import { NotificationModule } from './notification/notification.module';
import { OpenIdOAuth2Strategy, OpenIdToken } from './auth/openid-auth-strategy';
import { AUTH_STRATEGY, OAUTH_CLIENT_ID, OAUTH_SCOPE } from './shared/constants';
import { DEFAULT_THEME } from './themes/theme.default';
import { CF_THEME } from './themes/theme.cf';
import { XPLORE_THEME } from './themes/theme.xplore';
import { DialogModule } from './layout/dialog/dialog.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationService } from './shared/navigation/navigation.service';
import { UTUM_SUS_THEME } from './themes/theme.utum-sus';
import { AuthModule } from './auth/auth.module';
import { BuildDetailsService } from './core/services/build-details.service';
import { BuildUpdatedBannerComponent } from './layout/build-updated-banner/build-updated-banner.component';
import { STARTBAHN27_THEME } from './themes/theme.startbahn27';
import { UTUM_EIT_THEME } from './themes/theme.utum-eit';
import { CustomTenantPageComponent } from './layout/custom-tenant-page/custom-tenant-page.component';
import { PipeModule } from './pipe/pipe.module';
import { FilePickerService } from '@sleiss/ngx-awesome-uploader';
import { AngularDateHttpInterceptor } from './date-interceptor';
import { AHEAD_THEME } from './themes/theme.ahead';
import { NgxGraphModule } from '@swimlane/ngx-graph';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', `.json?buildTimestamp=${environment.buildTimestamp}`);
}

const tenantFactory = (tenantService: ManagementService) => {
  return () => tenantService.loadConfig();
};

const buildDetailsFactory = (buildDetailsService: BuildDetailsService) => {
  return () => buildDetailsService.initBuildDetails();
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NotFoundComponent,
    FooterComponent,
    PageRibbonComponent,
    MaintenanceBannerComponent,
    BuildUpdatedBannerComponent,
    CustomTenantPageComponent,
  ],
  imports: [
    NebularModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    AppRouting,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'default'}, [
      DEFAULT_THEME,
      CF_THEME,
      XPLORE_THEME,
      UTUM_SUS_THEME,
      STARTBAHN27_THEME,
      UTUM_EIT_THEME,
      AHEAD_THEME
    ]),
    NbMenuModule.forRoot(),
    NgxMatomoTrackerModule.forRoot({
      siteId: '1',
      trackerUrl: 'https://matomo.cirqus.de/',
      requireConsent: MatomoConsentMode.COOKIE,
      acceptDoNotTrack: true,
      disabled: !environment.production, // Disable locally
    }),
    NgxMatomoRouterModule,

    NbAuthModule.forRoot({
      strategies: [
        OpenIdOAuth2Strategy.setup({
          name: AUTH_STRATEGY,
          baseEndpoint: `${environment.ssoUrl}/api/oidc/provider/`,
          clientId: OAUTH_CLIENT_ID,
          authorize: {
            endpoint: 'auth',
            responseType: 'code',
            scope: OAUTH_SCOPE + ' code',
            redirectUri: location.protocol + '//' + location.host + '/auth/callback',
            params: {
              nonce: 'client',
              resource: 'https://cirqus.de',
            },
          },
          refresh: {
            endpoint: 'token',
            scope: OAUTH_SCOPE,
            grantType: 'refresh_token',
            requireValidToken: false,
          },
          token: {
            endpoint: 'token',
            scope: OAUTH_SCOPE + ' offline_access',
            grantType: 'authorization_code',
            requireValidToken: false,
            redirectUri: location.protocol + '//' + location.host + '/auth/callback',
            class: OpenIdToken,
          },
        })
      ],
      forms: {
        validation: {
          password: {
            minLength: 10,
          }
        },
      },
    }),
    NbToastrModule.forRoot({
      duration: 5000,
      position: NbGlobalLogicalPosition.TOP_END,
      preventDuplicates: true,
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    SharedModule,
    NotificationModule,
    DialogModule,
    AuthModule,
    PipeModule,
    NgxGraphModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true },
    { provide: NbRoleProvider, useClass: RoleProvider },
    { provide: RoleProvider, useClass: RoleProvider },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: tenantFactory,
      deps: [ManagementService, NavigationService, Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: buildDetailsFactory,
      deps: [BuildDetailsService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (managementService: ManagementService) => managementService.getTenantLocale(),
      deps: [ManagementService],
    },
    OpenIdOAuth2Strategy,
    FilePickerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor() {
    // Deep copy of the en-US locale
    const localeEnWithDeFormat = JSON.parse(JSON.stringify(localeEn));
    localeEnWithDeFormat[ɵLocaleDataIndex.FirstDayOfWeek] = 1; // Start with Monday
    localeEnWithDeFormat[ɵLocaleDataIndex.DateFormat] = localeDe[ɵLocaleDataIndex.DateFormat]; // Date formats
    localeEnWithDeFormat[ɵLocaleDataIndex.TimeFormat] = localeDe[ɵLocaleDataIndex.TimeFormat]; // Time formats

    registerLocaleData(localeEnWithDeFormat, 'de-DE');
  }

}
