import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { WaitForConfirmationComponent } from './wait-for-confirmation/wait-for-confirmation.component';
import { CallbackComponent } from './callback/callback.component';
import { InviteAcceptComponent } from './invite-accept/invite-accept.component';

export const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'confirmationRequired',
    component: WaitForConfirmationComponent
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    component: LogoutComponent,
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    component: ChangePasswordComponent,
  },
  {
    path: 'invite-accept/:invitationCode',
    component: InviteAcceptComponent,
    // NOTE: No AuthGuard here since, the component handles the auth check
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
