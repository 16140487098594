<nb-card>
  <nb-card-body>
    <h4 translate="team.activate.title" [translateParams]="{team: team?.name}"></h4>
    <div
      class="form-control-group accept-group"
    >
      <p class="subtitle" translate="team.activate.message" [translateParams]="{host: tenant?.name}"></p>
      <nb-checkbox
        name="terms"
        [(ngModel)]="termsAccepted"
        [required]="true"
      >
        <span translate="team.activate.readAgb"></span>
        <a [href]="termsUrl" target="_blank"
        ><strong translate="team.activate.agreeLinkAgb"></strong
        ></a>
        <span class="required-asterisk">*</span>
      </nb-checkbox>
    </div>

    <div
      class="form-control-group accept-group"
    >
      <nb-checkbox
        [(ngModel)]="privacyAccepted"
        [required]="true"
      >
        <span translate="team.activate.agreePrivacy"></span>
        <a [href]="privacyUrl" target="_blank"
        ><strong translate="team.activate.agreePrivacyLink"></strong
        ></a>
        <span class="required-asterisk">*</span>
      </nb-checkbox>
    </div>

    <button
      nbButton
      status="primary"
      class="margin-top"
      [disabled]="!privacyAccepted || !termsAccepted"
      (click)="activateTeam()"
      translate="team.activate.activateButton"
    ></button>

  </nb-card-body>
</nb-card>
