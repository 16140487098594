import { Injectable } from '@angular/core';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(
    private translate: TranslateService,
    private toastrService: NbToastrService,
  ) {
  }

  // TODO: Unify toasts and give them a type input
  successToast(titleTranslationKey: string, interpolateParams: any = undefined, messageTranslationKey?: string) {
    this.translate.get(titleTranslationKey, interpolateParams
    ).subscribe(title => {
      let message = null;
      if (messageTranslationKey) {
        message = this.translate.instant(messageTranslationKey);
      }
      this.toastrService.success(message, title, {position: NbGlobalPhysicalPosition.BOTTOM_RIGHT});
    });
  }

  warnToast(titleTranslationKey: string, interpolateParams: any = undefined, messageTranslationKey?: string) {
    this.translate.get(titleTranslationKey, interpolateParams
    ).subscribe(title => {
      let message = null;
      if (messageTranslationKey) {
        message = this.translate.instant(messageTranslationKey);
      }
      this.toastrService.warning(message, title);
    });
  }

  dangerToast(titleTranslationKey: string, interpolateParams: any = undefined, messageTranslationKey?: string) {
    this.translate.get(titleTranslationKey, interpolateParams
    ).subscribe(title => {
      let message = null;
      if (messageTranslationKey) {
        message = this.translate.instant(messageTranslationKey);
      }

      this.toastrService.danger(message, title);
    });
  }

  primaryToast(titleTranslationKey: string, interpolateParams: any = undefined, messageTranslationKey?: string) {
    this.translate.get(titleTranslationKey, interpolateParams
    ).subscribe(title => {
      let message = null;
      if (messageTranslationKey) {
        message = this.translate.instant(messageTranslationKey);
      }

      this.toastrService.primary(message, title, {icon: 'info-outline', duration: 2000, position: NbGlobalPhysicalPosition.BOTTOM_RIGHT});
    });
  }
}
