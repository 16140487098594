import { Injectable, TemplateRef, Type } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NbDialogConfig } from '@nebular/theme/components/dialog/dialog-config';
import { NbDialogRef } from '@nebular/theme/components/dialog/dialog-ref';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialogService: NbDialogService,
    private router: Router,
  ) { }

  public openDeleteDialog(subject: string, confirmationText: string, shouldTypeConfirm: boolean, onConfirm: () => void, onCancel?: () => void): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: { subject, confirmationText, onConfirm, onCancel, shouldTypeConfirm },
      hasScroll: false,
      closeOnBackdropClick: false,
    });
  }

  public openRemoveDialog(subject: string, confirmationText: string, shouldTypeConfirm: boolean, onConfirm: () => void, onCancel?: () => void): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        langKey: 'remove',
        subject,
        confirmationText,
        shouldTypeConfirm,
        onConfirm,
        onCancel
      },
      hasScroll: false,
      closeOnBackdropClick: false,
    });
  }

  public openConfirmDialog(subjectKey: string, hintKey: string, onConfirm: () => void, onCancel?: () => void, translateParams: any = {}): void {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        subjectKey,
        hintKey,
        onConfirm,
        onCancel,
        translateParams,
      },
      hasScroll: false,
      closeOnBackdropClick: false
    });
  }

  /**
   * Open the NbDialog and close it on forward/back navigation
   * @param content
   * @param userConfig
   */
  public open<T>(content: Type<T> | TemplateRef<T>, userConfig?: Partial<NbDialogConfig<Partial<T> | string>>): NbDialogRef<T> {
    const destroy$: Subject<void> = new Subject<void>();

    const ref = this.dialogService.open(content, userConfig);

    // Close ref on popstate event
    this.router.events
      .pipe(takeUntil(destroy$))
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          ref.close();
        }
      });

    ref.onClose.subscribe(() => {
      destroy$.next();
      destroy$.complete();
    });

    return ref;
  }
}
