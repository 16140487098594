import { Component, ViewChild } from '@angular/core';
import { NotificationService } from '../notification.service';
import { NbPopoverDirective } from '@nebular/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent {

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  unreadNotifications$: Observable<boolean>;

  constructor(private notificationService: NotificationService) {
    this.unreadNotifications$ = this.notificationService.hasUnreadNotifications;
  }

  closePopover() {
    this.popover.hide();
  }

}
