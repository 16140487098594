import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import {
  AddedToCourseNotification, ApplicationReviewAssignedNotification,
  ChapterReleasedNotification,
  EventUpcomingNotification,
  Notification, PersonAssignedNotification, RegistrationUpdatedStateNotification,
  ReviewNotification,
  TaskDeadlineNotification,
  TaskReleasedNotification,
  TeamInvitationReceivedNotification,
} from './models/notification.model';
import { Event } from '../event/event.model';
import { NbAuthService } from '@nebular/auth';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  // Create an observable to prevent subscribers to modify the Subject directly
  private hasUnreadNotificationsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly hasUnreadNotifications: Observable<boolean> = this.hasUnreadNotificationsSubject.asObservable();

  private isAuthenticated = false;

  constructor(
    private http: HttpClient,
    private nbAuthService: NbAuthService,
  ) {
    // Check every 5 minutes
    // We do not need to unsubscribe, as this service only exists once
    timer(0, 1000 * 60 * 5).subscribe(() => {
      if (this.isAuthenticated) {
        this.updateHasUnreadNotifications();
      }
    });

    this.nbAuthService.onAuthenticationChange().subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });
  }

  updateHasUnreadNotifications(): void {
    this.http.get<boolean>(`/api/notification/hasUnread`).subscribe((hasUnread) => {
      this.hasUnreadNotificationsSubject.next(hasUnread);
    });
  }

  getNotifications(includeReadNotifications = false): Observable<Notification[]> {
    return this.http.get<Notification[]>(`/api/notification?includeRead=${includeReadNotifications}`);
  }

  markNotificationAsRead(notificationId: string): Observable<Notification> {
    return this.http.post<Notification>(`/api/notification/${notificationId}/markAsRead`, {});
  }

  markAllNotificationsAsRead(): Observable<void> {
    return this.http.post<void>(`/api/notification/markAllAsRead`, {});
  }

  toTaskReleasedNotification(notification: Notification): TaskReleasedNotification {
    return notification as TaskReleasedNotification;
  }

  toChapterReleasedNotification(notification: Notification): ChapterReleasedNotification {
    return notification as ChapterReleasedNotification;
  }

  toTeamInvitationReceivedNotification(notification: Notification): TeamInvitationReceivedNotification {
    return notification as TeamInvitationReceivedNotification;
  }

  toAddedToCourseNotification(notification: Notification): AddedToCourseNotification {
    return notification as AddedToCourseNotification;
  }

  toReviewNotification(notification: Notification): ReviewNotification {
    return notification as ReviewNotification;
  }

  toTaskDeadlineNotification(notification: Notification): TaskDeadlineNotification {
    return notification as TaskDeadlineNotification;
  }

  toEventUpcomingNotification(notification: Notification): EventUpcomingNotification {
    return notification as EventUpcomingNotification;
  }

  toEventUpcomingNotificationEvent(notification: Notification): Event {
    return this.toEventUpcomingNotification(notification).task.fact as Event;
  }

  toApplicationReviewNotification(notification: Notification): ApplicationReviewAssignedNotification {
    return notification as ApplicationReviewAssignedNotification;
  }

  toPersonAssignedNotification(notification: Notification): PersonAssignedNotification {
    return notification as PersonAssignedNotification;
  }

  toRegistrationUpdatedStateNotification(notification: Notification): RegistrationUpdatedStateNotification {
    return notification as RegistrationUpdatedStateNotification;
  }
 }
