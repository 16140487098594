import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Feedback, Review, Comment } from './review.model';
import { Observable, of } from 'rxjs';
import { Submission } from '../form/models/submission.model';
import { Task } from '../task/task.model';
import { ChapterService } from '../chapter/chapter.service';
import { CourseService } from '../course/course.service';
import { Router } from '@angular/router';
import { SkillService } from '../skill/skill.service';

export interface ReviewServiceInterface {
  getReviewById(reviewId: string): Observable<Review | undefined>;
  getOwnReviewForSubmission(submissionId: string, courseShortName?: string): Observable<Review | undefined>;
  getAllReviewsForSubmission(submissionId: string, courseShortName?: string): Observable<{reviews: Array<Review>; task: Task}>;
  addCommentToFeedback(reviewId: string, feedbackId: string, commentMessage: string): Observable<{ success: boolean; comment: Comment }>;
  editComment(reviewId: string, feedbackId: string, commentId: string, commentMessage: string): Observable<{ success: boolean }>;
  addFeedbackToReview(reviewId: string, feedback: Feedback): Observable<{ success: boolean; feedback: Feedback }>;
  removeFeedbackFromReview(reviewId: string, feedbackId: string): Observable<{ success: boolean }>;
  resolveFeedback(reviewId: string, feedbackId: string): Observable<Review>;
  acknowledgeReview(reviewId: string): Observable<Review>;
  requestChanges(reviewId: string): Observable<Review>;
  reRequestReview(reviewId: string): Observable<Review>;
  approveSubmission(reviewId: string): Observable<Review>;
  declineSubmission(reviewId: string): Observable<Review>;
}

@Injectable({
  providedIn: 'root'
})
export class ReviewService implements ReviewServiceInterface {

  constructor(private http: HttpClient, private router: Router, private chapterService: ChapterService, private courseService: CourseService, private skillService: SkillService) {
  }

  getReviewById(reviewId: string): Observable<Review | undefined> {
    return this.http.get<Review | undefined>(`/api/review/${reviewId}`);
  }

  getOwnReviewForSubmission(submissionId: string, courseShortName: string): Observable<Review | undefined> {
    return this.http.get<Review | undefined>(`/api/review/ownForSubmission/${submissionId}`, {params: {courseShortName}});
  }

  getAllReviewsForSubmission(submissionId: string, courseShortName: string): Observable<{reviews: Array<Review>; task: Task}> {
    return this.http.get<{reviews: Array<Review>; task: Task}>(`/api/review/allForSubmission/${submissionId}`, {params: {courseShortName}});
  }

  /**
   * TODO: Think about whether this call is still needed, as reviews are automatically created on first get for each reviewer.
   * TODO: Possible use-case: Create a review for someone else
   * @param submissionId
   * @param review
   */
  createReviewForSubmission(submissionId: string, review: Review): Observable<Review> {
    return this.http.post<Review>(`/api/review/forSubmission/${submissionId}`, review);
  }

  addCommentToFeedback(reviewId: string, feedbackId: string, commentMessage: string): Observable<{ success: boolean; comment: Comment }> {
    return this.http.post<{ success: boolean; comment: Comment }>(`/api/review/${reviewId}/feedback/${feedbackId}/comments`, {message: commentMessage});
  }

  editComment(reviewId: string, feedbackId: string, commentId: string, commentMessage: string): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(`/api/review/${reviewId}/feedback/${feedbackId}/comments/${commentId}`, {message: commentMessage});
  }

  addFeedbackToReview(reviewId: string, feedback: Feedback): Observable<{ success: boolean; feedback: Feedback }> {
    return this.http.post<{success: boolean; feedback: Feedback}>(`/api/review/${reviewId}/feedback`, feedback);
  }

  removeFeedbackFromReview(reviewId: string, feedbackId: string,): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(`/api/review/${reviewId}/feedback/${feedbackId}`);
  }

  resolveFeedback(reviewId: string, feedbackId: string): Observable<Review> {
    return this.http.post<Review>(`/api/review/${reviewId}/feedback/${feedbackId}/resolve`, {});
  }

  acknowledgeReview(reviewId: string): Observable<Review> {
    return this.http.post<Review>(`/api/review/${reviewId}/acknowledgeReview`, {});
  }

  requestChanges(reviewId: string): Observable<Review> {
    return this.http.post<Review>(`/api/review/${reviewId}/requestChanges`, {});
  }

  reRequestReview(reviewId: string): Observable<Review> {
    return this.http.post<Review>(`/api/review/${reviewId}/reRequestReview`, {});
  }

  approveSubmission(reviewId: string): Observable<Review> {
    return this.http.post<Review>(`/api/review/${reviewId}/approveSubmission`, {});
  }

  declineSubmission(reviewId: string): Observable<Review> {
    return this.http.post<Review>(`/api/review/${reviewId}/declineSubmission`, {});
  }

  getSubmissionsAvailableForReview(): Observable<Submission[]> {
    return of ([{id: 'aaaaaaaaaaaaaaaaaaaaaaaa'} as Submission]);
  }

  navigateToReviewView(review: Review, taskId: string, courseId: string): void {
    this.chapterService.getChapterIdByTaskId(taskId, {courseId}).subscribe(chapterId => {
      this.courseService.getCourseShortNameByChapterId(chapterId).subscribe(shortName => {
        // eslint-disable-next-line max-len
        this.router.navigate(['/course', shortName, 'chapter', chapterId, 'task', taskId, 'review', 'view', review.id]);
      });
    });
  }

  navigateToSkillReviewView(review: Review, taskId: string, skillInstanceId: string, tenantId: string): void {
    this.skillService.getSkillIdBySkillInstanceId(skillInstanceId).subscribe(skillId => {
      this.router.navigate(['/skill', skillId, 'instance', skillInstanceId, 'task', taskId, 'review', 'view', review.id], {
        queryParams: {tenantId},
      });
    });
  }
}
