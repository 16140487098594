import { CourseAccessLevel, CourseMember } from '../../course-member/course-member.model';
import { Chapter } from '../../chapter/chapter.model';
import { Task } from '../../task/task.model';
import { Person, User } from '../../user/user.model';
import { Team } from '../../team/team.model';
import { TeamInvitation } from '../../team/team-invitation.model';
import { Course } from '../../course/course.model';
import { Review } from '../../review/review.model';
import { ApplicationReview } from '../../application/application.model';
import { Registration, RegistrationState } from '../../registration/registration.model';
import { SkillInstanceMember } from '../../skill/skill.model';

export enum NotificationType {
  TASK_RELEASED = 'taskReleased',
  CHAPTER_RELEASED = 'chapterReleased',
  REVIEW_APPROVED = 'reviewApproved',
  REVIEW_DECLINED = 'reviewDeclined',
  REVIEW_CHANGES_REQUESTED = 'reviewChangesRequested',
  REVIEW_RE_REQUESTED = 'reviewReRequested',
  REVIEW_ACKNOWLEDGED = 'reviewAcknowledged',
  TEAM_INVITATION_RECEIVED = 'teamInvitationReceived',
  ADDED_TO_COURSE = 'addedToCourse',
  MODIFIED_COURSE_ROLE = 'modifiedCourseRole',
  TASK_DEADLINE = 'taskDeadline',
  EVENT_UPCOMING = 'eventUpcoming',
  APPLICATION_REVIEW_ASSIGNED = 'applicationReviewAssigned',
  PERSON_ASSIGNED = 'personAssigned',
  REGISTRATION_STATE_UPDATE = 'registrationStateUpdate',
}

export interface Notification {
  id: string;
  type: NotificationType;
  receiverCourseMember?: CourseMember;
  receiverSkillInstanceMember?: SkillInstanceMember;
  receiverUser?: User;
  read: boolean;

  createdAt: Date;
  updatedAt: Date;
}

export interface ChapterReleasedNotification extends Notification {
  type: NotificationType.CHAPTER_RELEASED;
  chapter: Chapter;
  creatorCourse: Course;
}

export interface TaskReleasedNotification extends Notification {
  type: NotificationType.TASK_RELEASED;
  task: Task;
  creatorChapter: Chapter;
}

export interface TeamInvitationReceivedNotification extends Notification {
  invitationFrom: Team;
  invitation: TeamInvitation;
  creatorUser: User;
}

export interface AddedToCourseNotification extends Notification {
  type: NotificationType.ADDED_TO_COURSE;
  course: Course;
  accessLevel: CourseAccessLevel;
  creatorUser: User;
}

export interface ModifiedCourseRoleNotification extends Notification {
  type: NotificationType.MODIFIED_COURSE_ROLE;
  course: Course;
  accessLevel: CourseAccessLevel;
  creatorUser: User;
}

export interface ReviewNotification extends Notification {
  review: Review;
}

export interface ReviewApprovedNotification extends ReviewNotification {
  type: NotificationType.REVIEW_APPROVED;
}

export interface ReviewChangesRequestedNotification extends ReviewNotification {
  type: NotificationType.REVIEW_CHANGES_REQUESTED;
}

export interface ReviewReRequestedNotification extends ReviewNotification {
  type: NotificationType.REVIEW_RE_REQUESTED;
}

export interface IReviewAcknowledgedNotification extends ReviewNotification {
  type: NotificationType.REVIEW_ACKNOWLEDGED;
}

export interface TaskDeadlineNotification extends Notification {
  type: NotificationType.TASK_DEADLINE;
  task: Task;
}

export interface EventUpcomingNotification extends Notification {
  type: NotificationType.EVENT_UPCOMING;
  task: Task;
}

export interface ApplicationReviewAssignedNotification extends Notification {
  type: NotificationType.APPLICATION_REVIEW_ASSIGNED;
  applicationReview: ApplicationReview;
  creatorUser: User;
}

export interface PersonAssignedNotification extends Notification{
  person: Person;
  creatorUser: User;
}

export interface RegistrationUpdatedStateNotification extends Notification {
  type: NotificationType.REGISTRATION_STATE_UPDATE;
  registration: Registration;
  registrationState: RegistrationState;
  registrationProcessName: string;
}

