import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { NbThemeService } from '@nebular/theme';
import { Tenant, TenantLinks } from '../../tenant/tenant.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DEFAULT_LOCALE } from '../../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  tenant?: Tenant;

  constructor(
    private http: HttpClient,
    private themeService: NbThemeService,
    private router: Router,
  ) {
  }

  getTenantsForCurrentOrganization(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>('api/management/tenantsForOrganization');
  }

  /**
   * We convert it to promise so that this function can
   * be called by the APP_INITIALIZER
   */
  loadConfig() {
    // TODO: Remove this at some point, once all users migrated to auth setup
    const storedAuthToken = localStorage.getItem('auth_app_token');
    if (storedAuthToken) {
      const storedAuthTokenObject = JSON.parse(storedAuthToken);
      if (storedAuthTokenObject.name === 'nb:auth:jwt:token') {
        localStorage.removeItem('auth_app_token');
      }
    }

    return this.http.get<Tenant>(`/api/management/info`)
      .pipe(tap({
          next: data => this.setTenantAndTheme(data),
          error: () => {
            // If no tenant could be loaded (e.g. because the backend is down), reload page after 5s
            setTimeout(() => {
              location.reload();
            }, 5000);
          },
        }
      )).toPromise();
  }

  setTenantAndTheme(tenant: Tenant): void {
    this.tenant = tenant;
    if (tenant.themePrefix) {
      this.themeService.changeTheme(tenant.themePrefix + 'default');
    }

    // Host was reached using alias -> Navigate to correct hostname
    const currentHost = window.location.hostname;
    if (currentHost !== tenant.host) {
      // Check if a migration message should be shown
      if (this.getMigrationMessage()) {
        if (location.pathname !== '/migration') {
          this.router.navigate(['/migration'], {queryParams: {path: location.pathname, search: encodeURIComponent(location.search)}});
        }
      } else {
        // Directly forward
        window.location.hostname = tenant.host;
      }
    }
  }

  isFeatureEnabled(key: string): boolean {
    if (this.tenant?.features) {
      return this.tenant.features[key];
    }
    return false;
  }

  getLogoPrefix(): string {
    return this.tenant?.logoPrefix ? this.tenant.logoPrefix : '';
  }

  getTenantLinks(): TenantLinks {
    return this.tenant?.tenantLinks;
  }

  getTenantHost(): string {
    return this.tenant?.host;
  }

  getTenantId(): string {
    return this.tenant.id;
  }

  getMigrationMessage(): string {
    return this.tenant.hostAliases?.find(ha => ha.host === location.hostname)?.migrationMessage;
  }

  getTenantLocale(): string {
    return this.tenant.locale || DEFAULT_LOCALE;
  }
}
