<nb-card>
  <nb-card-body>
    <div *ngIf="message" [innerHtml]="message | safe: 'html'"></div>
  </nb-card-body>
  <nb-card-footer>
    <div class="justify-content-center row">
      <button
        nbButton
        class="col col-md-3 col-sm-6 col-12"
        (click)="confirm()"
        translate="buttons.confirm"
        status="primary"
      ></button>
    </div>
  </nb-card-footer>
</nb-card>
