import { Component } from '@angular/core';
import { MaintenanceService } from './maintenance.service';

@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss']
})
export class MaintenanceBannerComponent {

  maintenanceMessage?: string;

  constructor(private maintenanceService: MaintenanceService) {
    this.maintenanceService.getMaintenanceMessage().subscribe(maintenanceMessage => {
      this.maintenanceMessage = maintenanceMessage;
    });
  }
}
