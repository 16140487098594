import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ManagementService } from '../services/management.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanLoad, CanActivate {
  constructor(
    private tenantService: ManagementService,
    private router: Router,
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { data: {feature}} = route;
    return this.checkFeature(feature);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { data: {feature}} = route;
    return this.checkFeature(feature);
  }

  checkFeature(feature: string) {
    if (feature) {
      const isEnabled = this.tenantService.isFeatureEnabled(feature);
      if (isEnabled) {
        return true;
      }
    }
    this.router.navigate(['/dashboard']);
    return false;
  }
}
