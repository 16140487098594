import { Injectable } from '@angular/core';
import { BuildDetails } from './build-details';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuildDetailsService {
  public buildDetails: BuildDetails;

  private buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public buildIsUpToDate$ = this.buildIsUpToDateSubject.asObservable();

  constructor(private http: HttpClient) {
    this.buildDetails = new BuildDetails();
    this.pollForBuildNumber();
  }

  initBuildDetails(): Promise<BuildDetails> {
    return this.fetchBuildDetails().pipe(tap({
      next: data => {
        this.buildDetails = data;
      },
    })).toPromise();
  }

  fetchBuildDetails(): Observable<BuildDetails> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache'
      })
    };

    return this.http.get<BuildDetails>('/assets/build-details.json', httpOptions);
  }


  private pollForBuildNumber() {
    const pollInterval = 60000;

    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache'
      })
    };

    timer(pollInterval, pollInterval).subscribe(() => {
      this.fetchBuildDetails().subscribe(response => {
        const newCommitHash = response.commitHash;
        if (this.buildDetails.commitHash && this.buildDetails.commitHash !== newCommitHash) {
          this.buildIsUpToDateSubject.next(false);
        }
      });
    });
  }
}
