<span class="has-custom-badge">
  <a
    class="margin-left float-right"
    [nbPopover]="notificationBell"
    nbButton
    ghost
    matomoClickCategory="notification"
    matomoClickAction="bellClicked"
  >
    <nb-icon icon="bell-outline"></nb-icon>
  </a>
  <span [hidden]="(unreadNotifications$ | async) === false" class="custom-badge"></span>
</span>

<ng-template #notificationBell>
  <app-notification-list *ngIf="popover" (closePopover)="closePopover()"></app-notification-list>
</ng-template>

