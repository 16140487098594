<div class="row full-width align-content-center center-img">
  <div class="col-12">
    <p *ngIf="tenantLinks">
      <a translate="footer.privacy" [href]="privacyUrl" target="_blank"></a>
      |
      <a translate="footer.terms" [href]="termsUrl" target="_blank"></a>
      |
      <a translate="footer.legalNotice" [href]="legalUrl" target="_blank"></a>
      | Illustrations by
      <a href="https://storyset.com/" target="_blank">Storyset</a>
    </p>
  </div>
  <div class="col-12">
    <img
      src="../../../assets/images/logo_fullsize/logo.png"
      (click)="openCirqus()"
      [class.float-right]="!centerLogo"
      alt="cirqus Logo"
    />
  </div>
</div>
