import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NebularModule } from '../nebular.module';
import { PipeModule } from '../pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationBellComponent } from './notification-bell/notification-bell.component';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


@NgModule({
    imports: [
        CommonModule,
        NebularModule,
        PipeModule,
        TranslateModule,
        NgxMatomoTrackerModule,
        RouterModule,
        FormsModule,
    ],
  declarations: [
    NotificationListComponent,
    NotificationBellComponent,
    NotificationBannerComponent
  ],
  exports: [
    NotificationListComponent,
    NotificationBellComponent,
    NotificationBannerComponent,
  ],
})
export class NotificationModule {
}
