<nb-card *ngIf="loaded" accent="warning">
  <nb-card-body>
    <h4 translate="authPages.alternativeMember.title"></h4>
    <p [innerHTML]="'alerts.alternativeCourseMemberRequired' | translate: {currentContext}"></p>
    <nb-list *ngIf="courseMembers">
      <nb-list-item (click)="selectTeam(courseMember.team?.id)" *ngFor="let courseMember of courseMembers">
        <nb-user [name]="courseMember.name" [picture]="courseMember.profilePicturePreviewUrl" shape="rectangle"></nb-user>
      </nb-list-item>
    </nb-list>
  </nb-card-body>
</nb-card>
