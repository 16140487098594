import { Component, OnInit } from '@angular/core';
import { NbAuthService, NbLogoutComponent } from '@nebular/auth';
import { AUTH_STRATEGY } from '../../shared/constants';
import { TeamService } from '../../team/team.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent extends NbLogoutComponent implements OnInit {

  constructor(private teamService: TeamService, private authService: NbAuthService, public router: Router,) {
    super(authService,{}, router);
  }

  ngOnInit() {
    this.logout(AUTH_STRATEGY);
  }

  logout(strategy: string) {
    super.logout(strategy);
    this.teamService.removeTeamIdentifier(false, false);
  }
}
