import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToastrService } from '../services/toastr.service';
import { TeamService } from '../../team/team.service';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private teamService: TeamService,
  ) {
  }

  canActivate(): Observable<boolean> {
    const isTeam = this.teamService.isTeamMode();
    if (isTeam) {
      this.toastrService.dangerToast('alerts.userMode');
      this.router.navigate(['/dashboard']);
    }
    return of(!isTeam);
  }
}
