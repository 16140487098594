import { Component, OnDestroy, OnInit } from '@angular/core';
import { ManagementService } from '../../core/services/management.service';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'app-tenant-migration-confirm',
  templateUrl: './tenant-migration-confirm.component.html',
  styleUrls: ['./tenant-migration-confirm.component.scss']
})
export class TenantMigrationConfirmComponent implements OnInit, OnDestroy {

  message?: string;

  constructor(
    private managementService: ManagementService,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.layoutService.updateShowLoginButtons(false);
    const migrationMessage = this.managementService?.getMigrationMessage();
    if (migrationMessage) {
      this.message = migrationMessage;
    }
  }

  ngOnDestroy(): void {
    this.layoutService.updateShowLoginButtons(true);
  }

  confirm(): void {
    const path = this.route.snapshot.queryParamMap.get('path');
    const searchParam = decodeURIComponent(this.route.snapshot.queryParamMap.get('search'));
    const redirectUrl = `${location.protocol}//${this.managementService.tenant.host}:${location.port}${path}${searchParam}`;
    window.location.href = redirectUrl;
  }

}
