import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ChapterGroup, CommunitySettings, Course, SlackConfig } from './course.model';
import { catchError, map, tap } from 'rxjs/operators';
import { Person, User } from '../user/user.model';
import { Team } from '../team/team.model';
import { Chapter } from '../chapter/chapter.model';
import { UserService } from '../user/user.service';
import { CourseAccessLevel, CourseMember, Metadata } from '../course-member/course-member.model';
import { InvitedUser } from '../auth/invitedUser.model';
import { EmailCampaign } from '../email/email.model';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private userService: UserService, private http: HttpClient) {}

  public getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(`/api/course`);
  }

  public getOnboardingCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(`/api/course/onboarding`);
  }

  public getSelfEnrollmentCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(
      `/api/course/selfEnrollment`
    );
  }

  public enroll(
    id: string,
    enrollmentKey?: string
  ): Observable<{ success: boolean; message?: string }> {
    return this.http
      .post<{ success: boolean; message?: string }>(
        `/api/course/${id}/enroll`,
        { enrollmentKey }
      )
      .pipe(
        map(() => ({ success: true })),
        catchError((err) => of({ success: false, message: err.error.message }))
      );
  }

  public getCourseByShortName(shortName: string, forEdit = false): Observable<Course> {
    return this.http.get<Course>(
      `/api/course/getByShortname`, {params: {shortName, forEdit}}
    );
  }

  public getCourseByShortNameWithSubmissionStates(shortName: string, forEdit = false, courseMemberId?: string): Observable<Course> {
    const params: any = {
      shortName,
      forEdit,
    };

    if (courseMemberId) {
      params.courseMemberId = courseMemberId;
    }
    return this.http.get<Course>(
      `/api/course/getByShortnameWithSubmissionStates`, {params}
    );
  }

  public getCourseById(id: string, forEdit = false): Observable<Course> {
    return this.http.get<Course>(
      `/api/course/${id}`, {params: {forEdit}}
    );
  }

  public getCourseShortNameByChapterId(chapterId: string): Observable<string> {
    return this.http.get<string>(`/api/course/shortNameByChapterId`, {params: {chapterId}});
  }

  public getTagsForCourse(shortName: string, substring: string): Observable<string[]> {
    return this.http.get<string[]>(`/api/course/tags`, {params: {shortName, substring}});
  }

  public isShortNameAvailable(
    shortName: string
  ): Observable<{ available: boolean }> {
    return this.http.get<{ available: boolean }>(
      `/api/course/checkShortName`, {params: {shortName}}
    );
  }

  public getAllCoursesWithInstructorRole(): Observable<Course[]> {
    return this.http.get<Course[]>(`/api/course/withInstructorRole`);
  }

  /**
   * Create a course. The id is not required but will be created on the server.
   * The user MUST BE INSTRUCTOR to do this.
   *
   * @param course
   * @param programId Id of the program where this course should be part of
   */
  public createCourse(course: Course, programId?: string): Observable<Course> {
    const params = {};

    if (programId) {
      params['program'] = programId;
    }
    return this.http.post<Course>('/api/course', course, {params}).pipe(
      // Refresh stored shortnames
      tap(() => this.userService.getUserInfo().subscribe())
    );
  }

  /**
   * Update a course.
   * The user MUST BE INSTRUCTOR to do this.
   *
   * @param course
   * @param courseId
   */
  public updateCourse(course: Course, courseId: string): Observable<Course> {
    return this.http.put<Course>(
      `/api/course/${courseId}`,
      course
    );
  }

  /**
   * Update community settings of a course.
   * The user MUST BE INSTRUCTOR to do this.
   *
   * @param course
   * @param courseId
   */
  public updateCourseCommunitySettings(communitySettings: CommunitySettings, shortName: string): Observable<Course> {
    return this.http.put<Course>(
      `/api/course/${shortName}/communitySettings`,
      communitySettings
    );
  }

  public reorderChapters(courseId: string, chapters: string[], chapterGroups: ChapterGroup[]): Observable<Course> {
    return this.http.put<Course>(
      `/api/course/${courseId}/reorderChapters`,
      { chapters, chapterGroups },
    );
  }

  /**
   * Delete a course.
   * The user MUST BE INSTRUCTOR to do this.
   *
   * @param id
   */
  public deleteCourse(id: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(
      `/api/course/${id}`
    );
  }

  /**
   * Duplicate a course
   * Chapters and tasks will be duplicated, instructors and users are omitted.
   * The user calling this task will be added as an instructor to the new course.
   *
   * @param courseShortname
   * @param newShortName
   * @param newName
   */
  public duplicateCourse(
    courseShortname: string,
    newShortName: string,
    newName: string,
    programId: string,
  ): Observable<Course> {
    return this.http.post<Course>(
      `/api/course/${courseShortname}/duplicate`,
      { newShortName, newName, programId }
    );
  }

  /**
   * Add an user as user to a course. Removes him as instructor, if he is one.
   *
   * @param courseShortName
   * @param userId
   */
  public addUserToCourseAsUser(
    courseShortName: string,
    userId: string
  ): Observable<void> {
    return this.http.post<void>(
      `/api/course/${courseShortName}/user`, {}, {params: {user: userId}},
    );
  }

  /**
   * Add an user as instructor to a course. Removes him as user, if he is one.
   *
   * @param courseShortName
   * @param userId
   */
  public addUserToCourseAsInstructor(
    courseShortName: string,
    userId: string
  ): Observable<void> {
    return this.http.post<void>(
      `/api/course/${courseShortName}/instructor`, {}, {params: {user: userId}}
    );
  }

  /**
   * Add a user as coach to a course. Removes him as user, if he is one.
   *
   * @param courseShortName
   * @param userId
   */
  public addUserToCourseAsCoach(
    courseShortName: string,
    userId: string
  ): Observable<void> {
    return this.http.post<void>(
      `/api/course/${courseShortName}/coach?`, {}, {params: {user: userId}}
    );
  }

  /**
   * Add a team to a course.
   *
   * @param courseShortName
   * @param teamId
   */
  public addTeamToCourse(
    courseShortName: string,
    teamId: string
  ): Observable<void> {
    return this.http.post<void>(
      `/api/course/${courseShortName}/team`, {}, {params: {team: teamId}}
    );
  }

  /**
   * Removes an user as BOTH user AND instructor from a course.
   *
   * @param courseShortName
   * @param userId
   */
  public removeUserFromCourse(
    courseShortName: string,
    userId: string
  ): Observable<void> {
    return this.http.delete<void>(
      `/api/course/${courseShortName}/user`, {params: {user: userId}}
    );
  }

  /**
   * Removes a team from a course.
   *
   * @param courseShortName
   * @param teamId
   */
  public removeTeamFromCourse(
    courseShortName: string,
    teamId: string
  ): Observable<void> {
    return this.http.delete<void>(
      `/api/course/${courseShortName}/team`, {params: {team: teamId}}
    );
  }

  /**
   * Get all registered course members for a course.
   *
   * @param courseShortName
   * @param accessLevel
   */
  public getCourseMembersForCourse(courseShortName: string, accessLevel?: CourseAccessLevel): Observable<CourseMember[]> {
    const params: any = {};
    if (accessLevel) {
      params.accessLevel = accessLevel;
    }

    return this.http.get<CourseMember[]>(
      `/api/course/${courseShortName}/courseMembers`, {params}
    );
  }

  /**
   * Get all registered users for a course.
   *
   * @param courseShortName
   */
  public getUsersForCourse(courseShortName: string): Observable<User[]> {
    return this.http.get<User[]>(
      `/api/course/${courseShortName}/user`
    );
  }

  /**
   * Get all registered instructors for a course.
   *
   * @param courseShortName
   */
  public getInstructorsForCourse(courseShortName: string): Observable<User[]> {
    return this.http.get<User[]>(
      `/api/course/${courseShortName}/instructor`
    );
  }

  /**
   * Get all registered coaches for a course.
   *
   * @param courseShortName
   */
  public getCoachesForCourse(courseShortName: string): Observable<User[]> {
    return this.http.get<User[]>(
      `/api/course/${courseShortName}/coach`
    );
  }

  /**
   * Get all registered teams for a course.
   *
   * @param courseShortName
   */
  public getTeamsForCourse(courseShortName: string): Observable<Team[]> {
    return this.http.get<Team[]>(
      `/api/course/${courseShortName}/team`
    );
  }

  /**
   * Export the answers for the current course in an excel sheet.
   * Returns the blob of a .xlsx file
   *
   * @param courseShortName
   */
  public exportCourse(courseShortName: string): Observable<Blob> {
    return this.http.get(
      `/api/course/${courseShortName}/export`,
      {
        responseType: 'blob',
      }
    );
  }

  /**
   * Export the file uploads of submissions in a zip file.
   *
   * @param courseShortName
   */
  public exportCourseSubmissionsFiles(courseShortName: string): Observable<Blob> {
    return this.http.get(
      `/api/course/${courseShortName}/export-files`,
      {
        responseType: 'blob',
      }
    );
  }

  /**
   * Export the course members for the course in a excel sheet.
   * Returns the blob of a .xlsx file
   *
   * @param courseShortName
   */
  public exportCourseMembers(courseShortName: string): Observable<Blob> {
    return this.http.get(
      `/api/course/${courseShortName}/export/courseMembers`,
      {
        responseType: 'blob',
      }
    );
  }

  /**
   * Retrieve the slack config for the current course.
   * Requires admin permissions.
   * The secret is not populated.
   *
   * @param courseShortName
   */
  public getSlackConfig(
    courseShortName: string
  ): Observable<SlackConfig | undefined> {
    return this.http.get<SlackConfig | undefined>(
      `/api/course/${courseShortName}/slack`
    );
  }

  /**
   * Get meta data of a course.
   * The user MUST BE INSTRUCTOR to do this.
   *
   * @param shortName
   */
  public getCourseMetaDataByShortName(shortName: string): Observable<Course> {
    return this.http.get<Course>(
      `/api/course/${shortName}/metaData`
    );
  }

  /**
   * Update meta data.
   * The user MUST BE INSTRUCTOR to do this.
   *
   * @param course
   * @param shortName
   */
  public updateCourseMetaData(course: Course, shortName: string): Observable<Course> {
    return this.http.put<Course>(
      `/api/course/${shortName}/metaData`,
      course
    );
  }

  /**
   * Update the slack config for a course on the server.
   * Requires admin permissions.
   *
   * @param courseShortName
   * @param slackConfig
   */
  setSlackConfig(
    courseShortName: string,
    slackConfig: SlackConfig
  ): Observable<SlackConfig> {
    return this.http.post<SlackConfig>(
      `/api/course/${courseShortName}/slack`,
      slackConfig
    );
  }

  /**
   * Validate a given integration (should be called before saving it).
   *
   * @param metadata
   */
  validateIntegration(
    metadata: Metadata,
  ): Observable<{success: boolean; message?: string}> {
    return this.http.post<{success: boolean; message?: string}>(
      `/api/course/validateIntegration`,
      metadata,
    );
  }

  createCourseInvitationLink(courseShortName: string, chapterId?: string, taskId?: string): Observable<{invitedUser: InvitedUser; registerUrl: string}> {
    const params = {};

    if (chapterId) {
      params['chapterId'] = chapterId;
    }

    if (taskId) {
      params['taskId'] = taskId;
    }

    return this.http.post<{ invitedUser: InvitedUser; registerUrl: string }>(
      `/api/course/${courseShortName}/invitation/link`, {}, {params}
    );
  }

  getPersonsFromCourse(courseShortName: string): Observable<{communitySettings: CommunitySettings; persons: Person[]; teams: Team[]}> {
    return this.http.get<{communitySettings: CommunitySettings; persons: Person[]; teams: Team[]}>(`/api/course/${courseShortName}/persons`);
  }

  addPersonToCourse(courseShortName: string, personId: string): Observable<void> {
    return this.addPersonsToCourse(courseShortName, [personId]);
  }

  addPersonsToCourse(courseShortName: string, personIds: string[]): Observable<void> {
    return this.http.post<void>(`/api/course/${courseShortName}/persons/add`, {personIds});
  }

  removePersonFromCourse(courseShortName: string, personId: string): Observable<void> {
    return this.removePersonsFromCourse(courseShortName, [personId]);
  }

  removePersonsFromCourse(courseShortName: string, personIds: string[]): Observable<void> {
    return this.http.post<void>(`/api/course/${courseShortName}/persons/remove`, {personIds});
  }

  getCampaigns(courseShortName: string): Observable<EmailCampaign[]> {
    return this.http.get<EmailCampaign[]>(`/api/course/${courseShortName}/emailCampaigns`);
  }

  sendEmailForCourseMembers(courseShortName: string, emailContent: string, subject: string): Observable<void> {
    return this.http.post<void>(`/api/course/${courseShortName}/sendEmail`, {
      emailContent,
      subject
    });
  }

  extractChapterGroups(course: Course): { chapterGroups: ChapterGroup[]; chaptersWithoutGroup: Chapter[] } {
    const chaptersWithinChapterGroup: Chapter[] = [];

    const chapterGroups: ChapterGroup[] = [];
    course.chapterGroups?.forEach(chapterGroup => {
      const chaptersInGroup = chapterGroup.chapters.map(
        chapter => course.chapters.filter(c => c.id === chapter as unknown as string)[0]
      );
      chaptersWithinChapterGroup.push(...chaptersInGroup);
      chapterGroup.chapters = chaptersInGroup;
      chapterGroups.push(chapterGroup);
    });

    const chaptersWithinChapterGroupId = chaptersWithinChapterGroup.map(c => c.id);

    const chaptersWithoutGroup: Chapter[] = [];

    for (const chapter of course.chapters) {
      if (!chaptersWithinChapterGroupId.includes(chapter.id)) {
        chaptersWithoutGroup.push(chapter);
      }
    }

    return {chapterGroups, chaptersWithoutGroup};
  }
}
