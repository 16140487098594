import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Program } from '../../program/program.model';

export enum CourseEditEntryPoint {
  PROGRAM,
  COURSE,
}

export enum CourseEntryPoint {
  DASHBOARD,
  PROGRAM_OVERVIEW,
  ADMIN_DASHBOARD, // TODO: Remove this once this is no longer possible
}

export enum TaskEditEntryPoint {
  TASK,
  MASTER_EDIT,
  SKILL_INSTANCE_EDIT,
}

export enum ApplicationProcessEditEntryPoint {
  APPLICATION_PROCESS,
  PROGRAM,
}


export enum ApplicationProcessRoundEditEntryPoint {
  APPLICATION_PROCESS_EDIT,
  APPLICATION_PROCESS
}

export enum ApplicationReviewEntryPoint {
  APPLICATION_ROUND_RESULT,
  DASHBOARD,
}

export enum SkillInstanceEntryPoint {
  SKILL,
  DASHBOARD,
  MY_SKILLS
}

export enum SkillInstanceEditEntryPoint {
  SKILL_EDIT,
  SKILL_INSTANCE,
}

export enum TaskRoutingSourceEnum {
  ONBOARDING_COURSE,
  STANDARD_COURSE,
  SKILL,
}

export enum RegistrationProcessEditEntryPoint {
  REGISTRATION_PROCESS,
  COURSE_EDIT,
  SKILL_INSTANCE_EDIT
}

const programRegex = /^\/program\/[0-9a-fA-F]{24}(#.*)?$/;
const courseRegex = /^\/course\/[a-zA-Z0-9_-]+(#.*)?$/;
const masterEditRegex = /^\/course\/[a-zA-Z0-9_-]+\/edit(#.*)?$/;
const taskRegex = /^\/course\/[a-zA-Z0-9_-]+\/chapter\/[0-9a-fA-F]{24}\/task\/[0-9a-fA-F]{24}(#.*)?$/;
const skillRegex = /^\/skill\/[0-9a-fA-F]{24}(#.*)?$/;
const mySkillsRegex = /^\/skill\/mySkills(#.*)?$/;
const skillEditRegex = /^\/skill\/[0-9a-fA-F]{24}\/edit(#.*)?$/;
const skillInstanceRegex = /^\/skill\/[0-9a-fA-F]{24}\/instance\/[0-9a-fA-F]{24}(#.*)?$/;
const skillInstanceEditRegex = /^\/skill\/[0-9a-fA-F]{24}\/instance\/[0-9a-fA-F]{24}\/edit(#.*)?$/;
const skillTaskRegex = /^\/skill\/[0-9a-fA-F]{24}\/instance\/[0-9a-fA-F]{24}\/taskId\/[0-9a-fA-F]{24}(#.*)?$/;
const dashboardRegex = /^\/dashboard(#.*)?$/;
const programOverviewRegex = /^\/program(#.*)?$/;
const applicationProcessRegex = /^\/application\/[0-9a-fA-F]{24}(#.*)?$/;
const applicationProcessEditRegex = /^\/application\/[0-9a-fA-F]{24}\/edit(#.*)?$/;
const adminDashboardRegex = /^\/administration(#.*)?$/;
const applicationRoundResultRegex = /^\/application\/[0-9a-fA-F]{24}\/round\/[0-9a-fA-F]{24}\/result\/[0-9a-fA-F]{24}(#.*)?$/;
const registrationProcessRegex = /^\/registration\/[0-9a-fA-F]{24}?$/;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  courseEditEntryPoint: CourseEditEntryPoint = CourseEditEntryPoint.COURSE;
  courseEntryPoint: CourseEntryPoint = CourseEntryPoint.DASHBOARD;
  taskEditEntryPoint: TaskEditEntryPoint = TaskEditEntryPoint.TASK;
  applicationProcessEditEntryPoint: ApplicationProcessEditEntryPoint = ApplicationProcessEditEntryPoint.APPLICATION_PROCESS;
  applicationProcessRoundEditEntryPoint: ApplicationProcessRoundEditEntryPoint = ApplicationProcessRoundEditEntryPoint.APPLICATION_PROCESS_EDIT;
  applicationReviewEntryPoint: ApplicationReviewEntryPoint = ApplicationReviewEntryPoint.DASHBOARD;
  skillInstanceEntryPoint: SkillInstanceEntryPoint = SkillInstanceEntryPoint.SKILL;
  skillInstanceEditEntryPoint: SkillInstanceEditEntryPoint = SkillInstanceEditEntryPoint.SKILL_EDIT;
  registrationProcessEditEntryPoint: RegistrationProcessEditEntryPoint = RegistrationProcessEditEntryPoint.REGISTRATION_PROCESS;

  constructor(
    private router: Router,
  ) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (programRegex.test(e.urlAfterRedirects)) {
          this.courseEditEntryPoint = CourseEditEntryPoint.PROGRAM;
          this.applicationProcessEditEntryPoint = ApplicationProcessEditEntryPoint.PROGRAM;
          return;
        }

        if (courseRegex.test(e.urlAfterRedirects)) {
          this.courseEditEntryPoint = CourseEditEntryPoint.COURSE;
          return;
        }

        if (dashboardRegex.test(e.urlAfterRedirects)) {
         this.courseEntryPoint = CourseEntryPoint.DASHBOARD;
         this.skillInstanceEntryPoint = SkillInstanceEntryPoint.DASHBOARD;
         this.applicationReviewEntryPoint = ApplicationReviewEntryPoint.DASHBOARD;
         return;
        }

        if (programOverviewRegex.test(e.urlAfterRedirects)) {
          this.courseEntryPoint = CourseEntryPoint.PROGRAM_OVERVIEW;
          return;
        }

        if (adminDashboardRegex.test(e.urlAfterRedirects)) {
          // TODO: Remove once all courses are migrated
          this.courseEntryPoint = CourseEntryPoint.ADMIN_DASHBOARD;
          return;
        }

        if (masterEditRegex.test(e.urlAfterRedirects)) {
          this.taskEditEntryPoint = TaskEditEntryPoint.MASTER_EDIT;
          this.registrationProcessEditEntryPoint = RegistrationProcessEditEntryPoint.COURSE_EDIT;
          return;
        }

        if (taskRegex.test(e.urlAfterRedirects) || skillTaskRegex.test(e.urlAfterRedirects)) {
          this.taskEditEntryPoint = TaskEditEntryPoint.TASK;
          return;
        }

        if (applicationProcessRegex.test(e.urlAfterRedirects)) {
          this.applicationProcessEditEntryPoint = ApplicationProcessEditEntryPoint.APPLICATION_PROCESS;
          this.applicationProcessRoundEditEntryPoint = ApplicationProcessRoundEditEntryPoint.APPLICATION_PROCESS;
          return;
        }

        if (applicationProcessEditRegex) {
          this.applicationProcessRoundEditEntryPoint = ApplicationProcessRoundEditEntryPoint.APPLICATION_PROCESS_EDIT;
        }

        if (applicationRoundResultRegex.test(e.urlAfterRedirects)) {
          this.applicationReviewEntryPoint = ApplicationReviewEntryPoint.APPLICATION_ROUND_RESULT;
          return;
        }

        if (skillRegex.test(e.urlAfterRedirects)) {
          this.skillInstanceEntryPoint = SkillInstanceEntryPoint.SKILL;
          return;
        }

        if (skillEditRegex.test(e.urlAfterRedirects)) {
          this.skillInstanceEditEntryPoint = SkillInstanceEditEntryPoint.SKILL_EDIT;
          return;
        }

        if (skillInstanceRegex.test(e.urlAfterRedirects)) {
          this.skillInstanceEditEntryPoint = SkillInstanceEditEntryPoint.SKILL_INSTANCE;
          return;
        }

        if (skillInstanceEditRegex.test(e.urlAfterRedirects)) {
          this.taskEditEntryPoint = TaskEditEntryPoint.SKILL_INSTANCE_EDIT;
          this.registrationProcessEditEntryPoint = RegistrationProcessEditEntryPoint.SKILL_INSTANCE_EDIT;
          return;
        }

        if (mySkillsRegex.test(e.urlAfterRedirects)) {
          this.skillInstanceEntryPoint = SkillInstanceEntryPoint.MY_SKILLS;
          return;
        }

        if (registrationProcessRegex.test(e.urlAfterRedirects)) {
          this.registrationProcessEditEntryPoint = RegistrationProcessEditEntryPoint.REGISTRATION_PROCESS;
          return;
        }

      }
    });
  }

  public navigateBackFromCourseEdit(courseShortName: string, isOnboardingCourse: boolean, programId?: string | Program): void {
    if (this.courseEditEntryPoint === CourseEditEntryPoint.PROGRAM && programId) {
      this.router.navigate(['/program', programId]);
      return;
    }

    this.router.navigate(['/course', courseShortName]);
  }

  public navigateBackFromApplicationEdit(applicationProcessId: string | undefined, programId?: string | Program): void {
    if (!applicationProcessId || this.applicationProcessEditEntryPoint === ApplicationProcessEditEntryPoint.PROGRAM && programId) {
      this.router.navigate(['/program', programId]);
      return;
    }

    this.router.navigate(['/application', applicationProcessId]);
  }

  public navigateBackFromApplicationRoundEdit(applicationProcessId: string, applicationRoundId?: string): void {
    if (this.applicationProcessRoundEditEntryPoint === ApplicationProcessRoundEditEntryPoint.APPLICATION_PROCESS_EDIT) {
      this.router.navigate(['/application', applicationProcessId, 'edit']);
      return;
    }

    if (this.applicationProcessRoundEditEntryPoint === ApplicationProcessRoundEditEntryPoint.APPLICATION_PROCESS) {
      this.router.navigate(['/application', applicationProcessId], {queryParams: {applicationRoundId}});
      return;
    }
  }

  public navigateBackFromCourse(): void {
    if (this.courseEntryPoint === CourseEntryPoint.PROGRAM_OVERVIEW) {
      this.router.navigate(['/program']);
      return;
    }

    if (this.courseEntryPoint === CourseEntryPoint.ADMIN_DASHBOARD) {
      this.router.navigate(['/administration']);
      return;
    }

    this.router.navigate(['/dashboard']);
  }

  public navigateBackFromCourseTaskEdit(taskId: string, chapterId: string, courseShortName: string): void {
    if (this.taskEditEntryPoint === TaskEditEntryPoint.MASTER_EDIT) {
      this.router.navigate(['/course', courseShortName, 'edit'], {fragment: chapterId});
      return;
    }

    this.router.navigate(['/course', courseShortName, 'chapter', chapterId, 'task', taskId]);
  }

  public navigateBackFromSkillTaskEdit(taskId: string, skillInstanceId: string, skillId: string): void {
    if (this.taskEditEntryPoint === TaskEditEntryPoint.SKILL_INSTANCE_EDIT) {
      this.router.navigate(['/skill', skillId, 'instance', skillInstanceId, 'edit']);
      return;
    }

    this.router.navigate(['/skill', skillId, 'instance', skillInstanceId, 'taskId', taskId]);
  }

  public navigateBackFromReview(applicationProcessId: string, applicationRoundId: string, applicationRoundResultId: string): void {
    if (this.applicationReviewEntryPoint === ApplicationReviewEntryPoint.APPLICATION_ROUND_RESULT) {
      this.router.navigate(['/application', applicationProcessId, 'round', applicationRoundId, 'result', applicationRoundResultId]);
      return;
    }

    this.router.navigate(['/application', 'review-dashboard']);
  }

  public navigateBackFromSkillInstance(skillId: string): void {
    if (this.skillInstanceEntryPoint === SkillInstanceEntryPoint.SKILL) {
      this.router.navigate(['/skill', skillId]);
      return;
    }

    if (this.skillInstanceEntryPoint === SkillInstanceEntryPoint.MY_SKILLS) {
      this.router.navigate(['/skill', 'mySkills']);
      return;
    }

    this.router.navigate(['/dashboard']);
  }

  public navigateBackFromSkillInstanceEdit(skillId: string, skillInstanceId: string): void {
    if (this.skillInstanceEditEntryPoint === SkillInstanceEditEntryPoint.SKILL_INSTANCE) {
      this.router.navigate(['/skill', skillId, 'instance', skillInstanceId]);
      return;
    }

    this.router.navigate(['/skill', skillId, 'edit']);
  }
}
