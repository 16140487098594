import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { AUTH_STRATEGY } from '../../shared/constants';
import { NbAuthService, NbTokenService } from '@nebular/auth';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private nbAuthService: NbAuthService, private tokenService: NbTokenService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.nbAuthService.logout(AUTH_STRATEGY);
          this.tokenService.clear();
          this.nbAuthService.authenticate(AUTH_STRATEGY, {
            // Add current pathname to state -> Can be extracted by callback component
            state: window.location.pathname,
          })
            .subscribe(() => {
              // We have to subscribe to make the call
            });
        }
      })
    );
  }
}
