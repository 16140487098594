import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbAuthService, NbTokenService, NbTokenStorage } from '@nebular/auth';
import { Observable, pipe } from 'rxjs';
import { MAINTENANCE_HEADER, TEAM_IDENTIFIER, USER_ROLE } from '../shared/constants';
import { switchMap, tap } from 'rxjs/operators';
import { MaintenanceService } from '../layout/maintenance-banner/maintenance.service';
import { OpenIdToken } from './openid-auth-strategy';
import { AuthService } from './auth.service';
import { TeamService } from '../team/team.service';
import { RoleService } from './role.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private maintenanceService: MaintenanceService,
              private nbTokenService: NbTokenService,
              private tokenStorage: NbTokenStorage,
              private nbAuthService: NbAuthService,
              private authService: AuthService,
              private roleService: RoleService,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Ignore request to external urls - internal urls only contain the path, not the protocol and host
    if (req.url.match(/https?:\/\/.*/)) {
      return next.handle(req);
    }

    if (req.url === '/assets/build-details.json') {
      return next.handle(req);
    }

    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        switchMap(authenticated => {
          if (authenticated) {
            return this.nbAuthService.getToken().pipe(
              switchMap((token: OpenIdToken) => {
                const JWT = `Bearer ${token.getAccessTokenPayload()}`;

                let headers = req.headers;
                headers = headers.set('Authorization', JWT);

                const teamIdentifier = TeamService.getTeamIdentifier();
                if (teamIdentifier) {
                  headers = headers.set(TEAM_IDENTIFIER, teamIdentifier);
                }

                req = req.clone({
                  headers,
                });
                return next.handle(req).pipe(this.handleResponse());
              }),
            );
          } else {
            // Request is sent to server without authentication so that the client code
            // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
            return next.handle(req).pipe(this.handleResponse());
          }
        }),
      );
  }

  handleResponse = () =>
    pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const response = event;

          if (response.headers.has(MAINTENANCE_HEADER)) {
            // See https://stackoverflow.com/a/46141049/3802758
            this.maintenanceService.setNewMaintenanceMessage(decodeURIComponent(response.headers.get(MAINTENANCE_HEADER)));
          } else {
            this.maintenanceService.setNewMaintenanceMessage();
          }

          if (response.headers.has(USER_ROLE)) {
            const roleFromHeader = response.headers.get(USER_ROLE);
            this.roleService.updateLastReceivedRoleFromHeader(roleFromHeader);
          }
        }
      }));
}
