import { Component } from '@angular/core';
import { Tenant } from '../../tenant/tenant.model';
import { NbDialogRef } from '@nebular/theme';
import { ManagementService } from '../../core/services/management.service';
import { UserService } from '../user.service';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
  selector: 'app-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss']
})
export class PolicyDialogComponent {

  tenant: Tenant;
  termsAccepted = false;
  privacyAccepted = false;

  activatedByUser = false;

  termsUrl?: string;
  privacyUrl?: string;

  constructor(
    protected ref: NbDialogRef<PolicyDialogComponent>,
    private userService: UserService,
    private tenantService: ManagementService,
    private toastrService: ToastrService,
  ) {
    this.tenant = this.tenantService.tenant;

    this.termsUrl = this.tenant?.tenantLinks?.termsUrl || '/terms';
    this.privacyUrl = this.tenant?.tenantLinks?.privacyUrl || '/privacy';
  }

  acceptPolicy(): void {
    this.userService.acceptPolicy(this.tenant.currentPolicyVersion).subscribe(() => {
      this.toastrService.successToast('user.policyDialog.successTitle');
      this.ref.close({accepted: true});
    });
  }
}
