import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  maintenanceMessage: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  private lastMaintenanceMessage?: string;

  getMaintenanceMessage() {
    return this.maintenanceMessage;
  }

  setNewMaintenanceMessage(maintenanceMessage?: string) {
    if (maintenanceMessage === this.lastMaintenanceMessage) {
      return;
    }

    this.lastMaintenanceMessage = maintenanceMessage;

    this.maintenanceMessage.emit(maintenanceMessage);
  }
}
