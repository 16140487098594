import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
// Prevent shadowing of lib.dom File by custom file
import { File as FileModel, FileDatabase } from './file.model';
import { Observable, of } from 'rxjs';
import { FilePreviewModel, UploadResponse, UploadStatus } from '@sleiss/ngx-awesome-uploader';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private http: HttpClient,
  ) {}

  getFile(id: string, fileDatabase = FileDatabase.TENANT, tenantId?: string): Observable<FileModel> {
    const params: any = {
      fileDatabase
    };
    if (tenantId) { params.tenantId = tenantId; }

    return this.http.get<FileModel>(`/api/file/${id}`, {params});
  }

  getS3Url(id: string, fileDatabase = FileDatabase.TENANT, tenantId?: string, imageWidth: 100 | 200 | 400 | 800 | 1200 = undefined): Observable<string> {
    const params: any = {
      fileDatabase
    };
    if (tenantId) { params.tenantId = tenantId; }
    if (imageWidth) { params.imageWidth = imageWidth; }

    return this.http.get<string>(`/api/file/${id}/s3Url`, {params});
  }

  loadFileData(fileId: string, fileDatabase = FileDatabase.TENANT, tenantId?: string): Observable<Blob> {
    // First get the URL from the server, then download
    return this.getS3Url(fileId, fileDatabase, tenantId).pipe(
      switchMap(s3Url => this.loadBlobData(s3Url)),
    );
  }

  loadBlobData(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  /**
   * Initiate the upload and return the S3 upload URL
   *
   * @param file
   * @param fileName
   */
  public initiateUpload(file: File, fileName: string, fileDatabase = FileDatabase.TENANT): Observable<{file: FileModel; preSignedUploadUrl: string}> {
    return this.http.post<{file: FileModel; preSignedUploadUrl: string}>('/api/file/', {
      mimetype: file.type,
      fileName,
      fileSize: file.size,
    }, {params: {fileDatabase}});
  }

  public finishUpload(fileId: string, fileDatabase = FileDatabase.TENANT): Observable<FileModel> {
    return this.http.post<FileModel>(`/api/file/finishUpload/${fileId}`, {
    }, {params: {fileDatabase}});
  }


  public uploadFile(fileItem: FilePreviewModel, fileDatabase = FileDatabase.TENANT): Observable<UploadResponse> {
    let initialResponseSaved;

    // First initiate the upload and get the S3 URL
    return this.initiateUpload(fileItem.file as File, fileItem.fileName, fileDatabase).pipe(
      switchMap((initialResponse) => {
        initialResponseSaved = initialResponse;
        // Then perform the actual upload to S3
        const req = new HttpRequest('PUT', initialResponse.preSignedUploadUrl, fileItem.file, {reportProgress: true});
        return this.http.request(req);
      }
      ),
      switchMap((res: HttpEvent<any>) => {
        if (res.type === HttpEventType.Response) {
          // Upload finished -> Report to our server and return as state
          return this.finishUpload(initialResponseSaved.file.id, fileDatabase).pipe(
            map(() => ({
                body: initialResponseSaved.file,
                status: UploadStatus.UPLOADED
              }))
          );

        } else if (res.type ===  HttpEventType.UploadProgress) {
          /** Compute and show the % done: */
          const uploadProgress = +Math.round((100 * res.loaded) / res.total);
          return of({
            status: UploadStatus.IN_PROGRESS,
            progress: uploadProgress
          });
        } else {
          return of({
            status: UploadStatus.IN_PROGRESS,
          });
        }
      }),
    catchError(er => of({status: UploadStatus.ERROR, body: er }))
    );
  }

  public removeFile(fileItem: FilePreviewModel, fileDatabase = FileDatabase.TENANT) {
    return this.http.delete<UploadResponse>(
      `/api/file/${fileItem.uploadResponse.id}`,
      {params: {fileDatabase}}
    );
  }

  // https://stackoverflow.com/a/52680514/3802758
  /**
   * Method is used to download file.
   *
   * @param blob - Blob of the file
   * @param fileName - the name of the file
   */
  public downloadFileToClient(blob: Blob, fileName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
