import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Chapter } from './chapter.model';

@Injectable({
  providedIn: 'root',
})
export class ChapterService {
  constructor(private http: HttpClient) {}

  public getChapter(id: string): Observable<Chapter> {
    return this.http.get<Chapter>(`/api/chapter/${id}`);
  }

  /**
   * Get the chapter id in which the given task is included.
   *
   * At least one of the parameters courseId or courseShortName is required if the task is (or might be) part of an included skill instance.
   *
   * @param taskId
   * @param courseId
   * @param courseShortName
   */
  public getChapterIdByTaskId(taskId: string, { courseId, courseShortName }: {courseId?: string; courseShortName?: string}): Observable<string> {
    const params: any = {
      taskId
    };

    if (courseId) {
      params.courseId = courseId;
    }
    if (courseShortName) {
      params.courseShortName = courseShortName;
    }
    return this.http.get<string>(`/api/chapter/chapterIdByTaskId`, {params});
  }

  /**
   * Create a chapter. The id is not required but will be created on the server.
   * The user MUST BE ADMINISTRATOR to do this.
   *
   * @param chapter
   * @param courseShortname
   * @param chapterGroup
   * @param includeCode
   */
  public createChapter(
    chapter: Chapter,
    courseShortname: string,
    chapterGroup?: string,
    includeCode?: string,
  ): Observable<Chapter> {
    const params = {shortName: courseShortname};
    if (chapterGroup) {
      params['chapterGroup'] = chapterGroup;
    }
    if (includeCode) {
      params['includeCode'] = includeCode;
    }
    return this.http.post<Chapter>(
      `/api/chapter`,
      chapter, {params}
    );
  }

  /**
   * Update a chapter.
   * The user MUST BE ADMINISTRATOR to do this.
   *
   * @param chapter
   * @param chapterId
   */
  public updateChapter(
    chapter: Chapter,
    chapterId: string
  ): Observable<Chapter> {
    return this.http.put<Chapter>(
      `/api/chapter/${chapterId}`,
      chapter
    );
  }

  public reorderChapterTasks(
    chapterId: string,
    taskIds: string[],
  ): Observable<Chapter> {
    return this.http.put<Chapter>(
      `/api/chapter/${chapterId}/taskOrder`,
      taskIds,
    );
  }

  public moveTaskToChapter(
    taskId: string,
    fromChapterId: string,
    toChapterId: string,
    newIndex: number,
  ): Observable<void> {
    return this.http.put<void>(
      `/api/chapter/${fromChapterId}/moveToOtherChapter/${taskId}`,
      {},
      {params: {targetChapterId: toChapterId, newIndex}}
    );
  }

  /**
   * Delete a chapter.
   * The user MUST BE ADMINISTRATOR to do this.
   *
   * @param chapterId
   */
  public deleteChapter(chapterId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(
      `/api/chapter/${chapterId}`
    );
  }

  /**
   * Get next task for the given task within the current chapter.
   * Will throw an error if no next task exists.
   *
   * @param chapterId
   * @param taskId
   */
  public getSurroundingTasksForNavigation(chapterId: string, taskId: string): Observable<{ prevTaskId?: string; nextTaskId?: string }> {
    return this.http.get<{ prevTaskId?: string; nextTaskId?: string }>(
      `/api/chapter/${chapterId}/surroundingTasks`, {params: {taskId}}
    );
  }

  /**
   * Duplicate a chapter in a new course
   *
   * @param chapterId
   * @param targetCourseShortName
   * @param amount
   * @param chapterGroup
   */
  public duplicateChapter(chapterId: string, targetCourseShortName: string, amount: number, chapterGroupId?: string): Observable<{ message: string }> {
    const params: any = {targetCourseShortName, amount};

    if (chapterGroupId) {
      params.chapterGroupId = chapterGroupId;
    }

    return this.http.post<{ message: string }>(
      `/api/chapter/${chapterId}/duplicate`, {}, {params},
    );
  }
}
