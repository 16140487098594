<nb-card accent="warning">
  <nb-card-body>
    <h5 [translate]="subjectKey" [translateParams]="translateParams"></h5>
    <span [translate]="hintKey" [translateParams]="translateParams"></span>
    <div class="full-width d-flex justify-content-end margin-top-2">
      <button nbButton (click)="dismiss()" translate="buttons.cancel" status="warning" outline class="margin-right"></button>
      <button
        nbButton
        (click)="confirm()"
        translate="buttons.confirm"
        status="warning"
      ></button>
    </div>
  </nb-card-body>
</nb-card>
