import { Component, HostListener, OnInit } from '@angular/core';
import { ManagementService } from '../../core/services/management.service';
import { TenantLinks } from '../../tenant/tenant.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  tenantLinks: TenantLinks;
  centerLogo = true;

  termsUrl: string;
  privacyUrl: string;
  legalUrl: string;

  constructor(private tenantService: ManagementService) {
    this.tenantLinks = this.tenantService.getTenantLinks();

    this.termsUrl = this.tenantLinks?.termsUrl || '/terms';
    this.privacyUrl = this.tenantLinks?.privacyUrl || '/privacy';
    this.legalUrl = this.tenantLinks?.legalUrl || '/legal';
  }

  @HostListener('window:resize', []) updateEvents(): void {
    this.resizeApp();
  }

  ngOnInit(): void {
    this.resizeApp();
  }

  resizeApp(): void {
    this.centerLogo = window.innerWidth >= 768;
  }

  openCirqus(): void {
    window.open('https://cirqus.de', '_blank');
  }
}
