import { Metadata } from '../course-member/course-member.model';
import { Tenant } from '../tenant/tenant.model';
import { User } from '../user/user.model';
import { Condition } from '../condition/condition.model';
import { Task } from '../task/task.model';
import { Team } from '../team/team.model';
import { File } from '../file/file.model';
import { RegistrationProcess } from '../registration/registration.model';

export interface LearningJourney {
  skills: (Skill | SkillInstance)[]; // TODO: Think about this
  // TODO: Check name etc.
}

export enum ImageSource {
  SKILL_IMAGE = 'skill',
  CIRQUS = 'cirqus',
  UPLOADED = 'uploaded'
}

// TODO: Use this also for courses etc. and unify it
export enum SkillState {
  ARCHIVED = 'archived',
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

// TODO: Unify this with CourseAccessLevel to AccessLevel
export enum SkillAccessLevel {
  INSTRUCTOR = 'instructor',
  COACH = 'coach',
  USER = 'user',
}

export enum SkillInstanceType {
  LIVE_SKILL_INSTANCE = 'LiveSkillInstance',
  SELF_PACED_SKILL_INSTANCE = 'SelfPacedSkillInstance'
}

export enum SkillTaskType {
  REGISTRATION = 'registrationTask',
  APPLICATION = 'applicationTask',
  FEEDBACK = 'feedbackTask',
  PREPERATION = 'preparationTask',
  EVENT = 'event',
  FOLLOWUP = 'followUpTask',
  CUSTOM = 'customTasks',
  ASSESSMENT = 'assessmentTask'
}

export enum SkillCategory {
  PEOPLE = 'people',
  IDEA_VISION = 'idea_vision',
  SOFTWARE_PRODUCT = 'software_product',
  HARDWARE_PRODUCT = 'hardware_product',
  MARKETING = 'marketing',
  SALES = 'sales',
  FINANCE_FUNDRAISING = 'finance_fundraising',
  LEGAL = 'legal',
}

export enum SkillAuthTrigger {
  JOIN_INSTANCE = 'joinInstance',
  SHOW_MY_SKILLS = 'showMySkills',
}

export interface SkillInstanceVisibilitySettings {
  tenants: {
    [key: string]: {
      visibleInMarketplace: boolean;
      visibleInLibrary: boolean;
    };
  };
}

export interface PreviewDescriptionPart {
  name: string;
  text: string;
}

export interface Skill {
  id?: string;
  originTenant?: Tenant;
  name: string;
  type: SkillInstanceType;
  tags: SkillCategory[];
  skillManagers: User[];
  image?: File; // TODO: Add fallbackimage in client
  cirqusFallbackImage?: string; // See image of Chapter
  imageSource: ImageSource;
  skillInstances?: SkillInstance[]; // At least one instance

  readonly nextDate?: Date; // Only for skills with LiveSkillInstances

  preview?: {
    about?: string;
    prerequisites?: {
      text?: string;
      skillDependencies?: Skill[];
    };
    learnings?: string;
    custom: PreviewDescriptionPart[];
  };
}

export interface SkillInstance {
  id?: string;
  type: SkillInstanceType;
  tags: string[];
  skill: Skill;
  state: SkillState;
  visibilitySettings: SkillInstanceVisibilitySettings;

  includeCode?: string; // TODO: Remove this once include-logic is finished
  certificates?: any; // TODO

  skillInstanceMetadata?: Metadata[];
  individualMetadata?: Metadata[];

  conditions?: Condition[];

  applicationTask?: Task;
  feedbackTask?: Task;

  registrationProcess?: RegistrationProcess;

  readonly registrationRequired?: boolean; // virtual field
}

export interface SkillContentType {
  titleKey: string;
  title?: string;
  link: string;
  exists: boolean;
  queryParams: {taskType: string; skill?: boolean; skillInstanceId?: string};
}

export interface LiveSkillInstance extends SkillInstance {
  preparationTask?: Task;
  event: Task;
  followUpTask?: Task;
}

export interface SelfPacedSkillInstance extends SkillInstance {
  customTasks: Task[];
  assessmentTask: Task;
  durationInMinutes: number;
}

export interface SkillInstanceMember extends Document {
  id?: string;
  team?: Team;
  user?: User;
  skillInstance: SkillInstance;
  accessLevel: SkillAccessLevel;
  tenant?: Tenant;


  // Map from metadata id to value
  metadataValues?: Map<string, string>;

  readonly name: string; // Virtual field
  readonly profilePicturePreviewUrl?: string; // virtual field
  readonly s3ProfilePictureKey?: string; // Virtual field

  completedTasks: [Task] | [string]; // Not persisted
}
