import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ManagementService } from '../../services/management.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag: string | string[];
  @Input() featureFlagDisabledRef: TemplateRef<any>;

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private featureFlagService: ManagementService,
  ) { }

  ngOnInit() {
    let isEnabled;
    if (typeof this.featureFlag === 'string') {
      isEnabled = this.featureFlagService.isFeatureEnabled(this.featureFlag);
    } else {
      // Array -> Check that at least one of the provided feature flags is enabled
      isEnabled = this.featureFlag.some(f => this.featureFlagService.isFeatureEnabled(f));
    }
    if (isEnabled) {
      this.vcr.createEmbeddedView(this.tpl);
    } else if (this.featureFlagDisabledRef) {
      this.vcr.createEmbeddedView(this.featureFlagDisabledRef);
    }
  }

}
