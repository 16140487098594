import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NotificationSettings, User } from './user.model';
import { CourseAccessLevel } from '../course-member/course-member.model';
import { tap } from 'rxjs/operators';
import { NbAuthService } from '@nebular/auth';
import { TEAM_IDENTIFIER } from '../shared/constants';
import { FileDatabase } from '../file/file.model';
import { FileService } from '../file/file.service';
import { RoleService } from '../auth/role.service';
import { ManagementService } from '../core/services/management.service';
import { NbDialogService } from '@nebular/theme';
import { PolicyDialogComponent } from './policy-dialog/policy-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  coursesInstructorShortNames: string[] = [];
  coursesCoachShortNames: string[] = [];
  skillsManagerIds: string[] = [];
  user: User;
  userData: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  userData$ = this.userData.asObservable();
  showNotificationBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showNotificationBanner$ = this.showNotificationBanner.asObservable();
  roleService: RoleService;

  policyAcceptDialogOpen = false;

  constructor(private fileService: FileService,
              private managementService: ManagementService,
              private nbAuthService: NbAuthService,
              private http: HttpClient,
              private injector: Injector,
              private dialogService: NbDialogService) {
    // We inject the role service like that to break the dependency cycle
    setTimeout(() => this.roleService = injector.get(RoleService), 0);
    nbAuthService.onTokenChange().subscribe((token) => {
      if (!token.isValid()) {
        this.coursesInstructorShortNames = [];
        this.coursesCoachShortNames = [];
      }
    });
  }

  /**
   * Get the User object of the currently logged-in user from the server.
   */
  getUserInfo(): Observable<User> {
    return this.http.get<User>(`/api/user/info`).pipe(
      tap(user => {
        this.coursesInstructorShortNames = user.courseMembers
          .filter(cm => cm.accessLevel === CourseAccessLevel.INSTRUCTOR)
          .map(cm => cm.course.shortName);
        this.coursesCoachShortNames = user.courseMembers
          .filter(cm => cm.accessLevel === CourseAccessLevel.COACH)
          .map(cm => cm.course.shortName);
        this.skillsManagerIds = user.skillsManagerIds;

        // Set client only properties
        const teamIdentifier = localStorage.getItem(TEAM_IDENTIFIER);
        user.isTeam = !!teamIdentifier;
        if (user.isTeam) {
          user.currentTeamId = teamIdentifier;

          const nameAndProfilePictureRes = this.calculateCurrentTeamNameAndProfilePictureLink(user, teamIdentifier);
          user.currentName = nameAndProfilePictureRes.name;
          user.currentProfilePicturePreviewUrl = nameAndProfilePictureRes.profilePicturePreviewUrl;
        } else {
          user.currentName = user.fullName;
          user.currentProfilePicturePreviewUrl = user.profilePicturePreviewUrl;
        }
        this.user = user;
        this.userData.next(user);

        this.updateShowNotificationReminder(user.notificationSettings);

        // Check if user has to accept new privacy policy
        const lastAcceptedPolicyVersion = this.user.activatedTenants[this.managementService.tenant.id].lastAcceptedPolicyVersion;
        if (!lastAcceptedPolicyVersion || this.managementService.tenant.currentPolicyVersion > lastAcceptedPolicyVersion) {
          // This is quite hacky but is required to not trigger the dialog on the /terms, /pricacy or /legal pages (so that the user can actually view the new policies)
          const termsOrPrivacyOrLegalPage = location.pathname === '/terms' || location.pathname === '/privacy' || location.pathname === '/legal';
          if (!this.policyAcceptDialogOpen && !termsOrPrivacyOrLegalPage) {
            this.policyAcceptDialogOpen = true;

            this.dialogService.open(PolicyDialogComponent, {
              closeOnBackdropClick: false,
              closeOnEsc: false,
            }).onClose.subscribe(({accepted}) => {
              if (accepted) {
                this.policyAcceptDialogOpen = false;
                this.user.activatedTenants[this.managementService.tenant.id].lastAcceptedPolicyVersion = this.managementService.tenant.currentPolicyVersion;
              }
            });
          }
        }
      })
    );
  }

  /**
   * Accept a specific policy version
   * @param policyVersion
   */
  acceptPolicy(policyVersion: number): Observable<void> {
    return this.http.post<void>(`/api/user/accept-policy`, {}, {params: {policyVersion}});
  }

  /**
   * Updates properties on a user
   *
   * @param user
   */
  updateUser(user: User): Observable<User> {
    return this.http.put<User>(`/api/user/update-user`, user);
  }

  /**
   * Get all users from the server. Only possible as administrator.
   */
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`/api/user`);
  }

  /**
   * Export all users for the current tenant. Only possible as administrator.
   */
  exportUsers(): Observable<Blob> {
    return this.http.get(`/api/user/export`, {responseType: 'blob'});
  }

  /**
   * Get all users that have a name or email that contains a substring.
   *
   * @param substring
   */
  getMatchingUsers(substring: string): Observable<User[]> {
    return this.http.get<User[]>(
      `/api/user/matching?substring=${substring}`
    );
  }

  /**
   * Grant administrator rights to the specified user. The performing user must be administrator.
   *
   * @param id
   */
  grantAdministrationRights(id: string): Observable<User> {
    return this.http.post<User>(`/api/user/${id}/admin`, {});
  }

  /**
   * Grant administrator rights to the specified user. The performing user must be administrator.
   *
   * @param id
   */
  revokeAdministrationRights(id: string): Observable<User> {
    return this.http.delete<User>(`/api/user/${id}/admin`);
  }

  /**
   * Activate a user. The performing user must be administrator.
   *
   * @param id
   */
  activateUser(id: string): Observable<User> {
    return this.http.post<User>(`/api/user/${id}/activate`, {});
  }

  /**
   * Import the users given in file.
   *
   * @param file A CSV-file containing the users that should be supported.
   */
  importUsers(file: File): Observable<{ createdUsers: User[]; existingUsers: User[] }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<{ createdUsers: User[]; existingUsers: User[] }>(`/api/user/import`, formData);
  }

  getCoursesInstructorShortNames(): Observable<string[]> {
    return of(this.coursesInstructorShortNames);
  }

  getCoursesCoachShortNames(): Observable<string[]> {
    return of(this.coursesCoachShortNames);
  }

  getSkillsManagerIds(): Observable<string[]> {
    return of(this.skillsManagerIds);
  }

  getCurrentUserId(): string {
    return this.user.id;
  }

  /**
   * Update the notification settings of the current user.
   *
   * @param notificationSettings
   */
  updateNotificationSettings(notificationSettings: NotificationSettings): Observable<NotificationSettings> {
    return this.http.post<NotificationSettings>(`/api/user/notificationSettings`, notificationSettings).pipe(
      tap(res => {
        this.updateShowNotificationReminder(res);
      })
    );
  }

  public populatePictureLinkIfAvailable(user: User): void {
    if (!user.picture || user.pictureLink) {
      return;
    }

    this.fileService.getS3Url(user.picture.id, FileDatabase.MANAGEMENT, undefined, 400).subscribe(link => user.pictureLink = link);
  }

  /**
   * Delete an user. The performing user must be super administrator.
   *
   * @param id
   */
  deleteUser(id: string): Observable<User> {
    return this.http.delete<User>(`/api/user/${id}`, {});
  }

  /**
   * Remove a user from the current tenant. The performing user must be administrator.
   *
   * @param id
   */
  removeUserFromTenant(id: string): Observable<void> {
    return this.http.delete<void>(`/api/user/removeFromTenant/${id}`, {});
  }

  getCalendarUrls(): Observable<{ calendarUrl: string; webcalUrl: string }> {
    return this.http.get<{ calendarUrl: string; webcalUrl: string }>(`/api/user/calendarLink`);
  }

  updateShowNotificationReminder(notificationSettings: NotificationSettings): boolean {
    if (localStorage.getItem('notificationReminderDismissed') === 'true') {
      // Reminder was already shown
      this.showNotificationBanner.next(false);
      return;
    }

    // Reminder was not already shown -> If at least one setting is enabled: Do not show the reminder
    if (notificationSettings?.email?.addedToCourseOrRoleModified || notificationSettings?.email?.teamInvitation) {
      this.showNotificationBanner.next(false);
      return;
    }


    this.showNotificationBanner.next(true);
  };

  dismissNotificationReminder(): void {
    localStorage.setItem('notificationReminderDismissed', 'true');
    this.showNotificationBanner.next(false);
  }

  calculateCurrentTeamNameAndProfilePictureLink(user: User, teamIdentifier: string): { name: string; profilePicturePreviewUrl: string } {
    // Set current username
    const team = user.teams?.find((t) => t.id === teamIdentifier);
    const name = team ? team.name : user.fullName;
    const profilePicturePreviewUrl = team ? team.profilePicturePreviewUrl : user.profilePicturePreviewUrl;

    return {name, profilePicturePreviewUrl};
  }

  reloadUserInfo(): void {
    this.getUserInfo().subscribe();
    this.roleService.fetchAndUpdateLastReceivedRoleFromToken();
  }
}
