<nb-layout-header fixed>
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col-auto">
        <img
          *ngIf="isLargeScreen"
          [routerLink]="authenticated ? '/dashboard' : ''"
          [src]='"../../../assets/images/logo_fullsize/" + tenant.logoPrefix + "logo.png"'
          alt="Tenant Logo"
          class="logo profile"
        />
        <img
          *ngIf="!isLargeScreen"
          [routerLink]="authenticated ? '/dashboard' : ''"
          [src]='"../../../assets/images/logo_small/" + tenant.logoPrefix + "logo.png"'
          alt="Tenant Logo"
          class="logo profile"
        />
      </div>
      <div class="col text-end">
        <div *ngIf="authenticated">
          <div [nbPopover]="menuItems" class="float-right margin-left menu guided-tour-menu d-flex" nbPopoverTrigger="click"
               nbPopoverPlacement="bottom-start">
            <nb-user [name]="(user$ | async)?.currentName" [onlyPicture]="!isLargeScreen" [picture]="(user$ | async)?.currentProfilePicturePreviewUrl" class="multi-user"
                     shape="rectangle"></nb-user>
            <nb-icon
              class="dropdownArrow"
              icon="arrow-ios-downward-outline"
              status="basic"
            ></nb-icon>
          </div>
          <app-notification-bell class="float-right"></app-notification-bell>

        </div>
        <div *ngIf="!authenticated && (shouldShowLoginButtons$ | async) === true">
          <button
            (click)="login()"
            class="login"
            nbButton
            status="basic">
            <nb-icon icon="log-in-outline"></nb-icon>
            <span translate="auth.login" *ngIf="!isSmallScreen"></span>
          </button>
          <button
            (click)="register()"
            nbButton
            status="primary">
            <nb-icon icon="person-add-outline" *ngIf="!isSmallScreen || isSuperSmallScreen"></nb-icon>
            <span translate="auth.register" *ngIf="!isSuperSmallScreen"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</nb-layout-header>

<ng-template #menuItems>
  <ng-container *ngIf="user$ | async as user">
    <ng-container *ngIf="user.teams && user.teams.length > 0 || user.isTeam">
      <nb-list class="guided-tour-menu-items">
        <nb-list-item *ngIf="user.isTeam" (click)="selectUser()">
          <nb-user [name]="user.fullName"  [picture]="user.profilePicturePreviewUrl" shape="rectangle" size="small"></nb-user>
        </nb-list-item>
        <nb-list-item (click)="selectTeam(team)" *ngFor="let team of user.teams"
                      [hidden]="team.id === user?.currentTeamId">
          <nb-user [name]="team.name" [picture]="team.profilePicturePreviewUrl" shape="rectangle" size="small"></nb-user>
        </nb-list-item>
      </nb-list>
      <hr>
    </ng-container>
    <nb-list>
      <nb-list-item (click)="onMenuClick('dashboard')">
        <span class="paragraph-2" translate="auth.dashboard"></span>
      </nb-list-item>
      <nb-list-item (click)="onMenuClick('userProfile')" *ngIf="!user.isTeam">
        <span translate="auth.profile" class="paragraph-2"></span>
      </nb-list-item>
      <nb-list-item (click)="onMenuClick('teamProfile')" *ngIf="user.isTeam">
        <span translate="auth.teamProfile" class="paragraph-2"></span>
      </nb-list-item>
      <nb-list-item (click)="onMenuClick('joinTeam')" *ngIf="!user.isTeam">
        <span class="paragraph-2" translate="auth.joinTeam"></span>
      </nb-list-item>
      <nb-list-item (click)="onMenuClick('persons')">
        <span class="paragraph-2" translate="auth.community"></span>
      </nb-list-item>
      <nb-list-item (click)="onMenuClick('skill')" *featureFlag="'skillMarketplace'">
        <span class="paragraph-2" translate="auth.skill"></span>
      </nb-list-item>
    </nb-list>
  </ng-container>
  <hr>
  <ul>
    <!-- TODO: Remove this and move it to admin dashboard -->
    <li (click)="onMenuClick('team')" *nbIsGranted="['view', 'team-overview']">
      <span class="paragraph-2" translate="auth.teamOverview"></span>
    </li>
    <li (click)="onMenuClick('program')" *nbIsGranted="['view', 'program-dashboard']">
      <span class="paragraph-2" translate="auth.program"></span>
    </li>
    <ng-container *featureFlag="'skillMarketplace'; disabledRef adminSkill">
      <!-- This is an empty container that is only used as negation of the *featureFlag -->
    </ng-container>
    <ng-template #adminSkill>
      <!-- This is only shown if the 'skillMarketplace' feature flag is NOT enabled -->
      <ng-container *featureFlag="'skills'">
        <li (click)="onMenuClick('skill')" *nbIsGranted="['view', 'skill']">
          <span class="paragraph-2" translate="auth.skill"></span>
        </li>
      </ng-container>
    </ng-template>
    <li (click)="onMenuClick('admin')" *nbIsGranted="['view', 'admin-dashboard']">
      <span class="paragraph-2" translate="auth.adminDashboard"></span>
    </li>
    <li (click)="onMenuClick('tenantDashboard')" *nbIsGranted="['view', 'tenant-dashboard']">
      <span class="paragraph-2" translate="auth.tenantDashboard"></span>
    </li>
  </ul>
  <hr *nbIsGranted="['view', 'skill']">
  <nb-list>
    <nb-list-item (click)="onMenuClick('logout')">
      <span class="paragraph-2" translate="auth.logout"></span>
    </nb-list-item>
  </nb-list>
</ng-template>
