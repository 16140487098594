import { Chapter } from '../chapter/chapter.model';
import { Metadata } from '../course-member/course-member.model';
import { Program } from '../program/program.model';
import { Person } from '../user/user.model';

export interface Course {
  id?: string;
  readonly courseType?: CourseType;
  readonly program?: Program;
  name: string;
  description: string;
  shortName: string;
  displayName?: string;
  chapters: Chapter[];
  enrollmentKey?: string;
  selfEnrollmentEnabled?: boolean;
  enrollmentKeyRequired?: boolean;
  chapterGroups?: ChapterGroup[];
  courseMetadata?: Metadata[];
  individualMetadata?: Metadata[];
  enrollmentType: CourseEnrollmentType;
  reminderSettings: ReminderSettings;
  communitySettings?: CommunitySettings;
  certificatesEnabledForCourseMembers?: boolean;
  lateSubmissionsAllowed: boolean;
  reviews: CourseReviewState;
  persons?: Person[];
  releaseConditionsEnabled: boolean;
  archived?: boolean; // Only set via coursemember
}

export interface ReminderSettings {
  weeklyReminderDay: Weekday;
  releaseReminderFrequency: ReminderFrequency;
  deadlineReminderFrequency: ReminderFrequency;
  eventReminderFrequency: ReminderFrequency;
  slackConfig?: SlackConfig;
}

export interface CommunitySettings {
  showInstructors: boolean;
  showCoaches: boolean;
  showParticipants: boolean;
}

export interface SlackConfig {
  channelId?: string;
}

export interface ChapterGroup {
  id?: string;
  name: string;
  chapters: Chapter[];
  isInEditMode?: boolean; // Only for local editing purposes
}

export enum CourseType {
  STANDARD = 'standard',
  ONBOARDING = 'onboarding',
}

export enum CourseEnrollmentType {
  UNRESTRICTED = 'unrestricted',
  USER = 'user',
  TEAM = 'team',
}

export enum CourseReviewState {
  DISABLED = 'disabled',
  REVIEW = 'review', // Review by coach/instructor
  PEER = 'peer',
}

export enum ReminderFrequency {
  WEEKLY = 'weekly',
  DAILY = 'daily',
  ALL = 'all',
  NONE = 'none',
}

export enum Weekday {
  MONDAY = 1,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}
