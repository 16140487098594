import { Component } from '@angular/core';
import { BuildDetailsService } from '../../core/services/build-details.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-build-updated-banner',
  templateUrl: './build-updated-banner.component.html',
  styleUrls: ['./build-updated-banner.component.scss']
})
export class BuildUpdatedBannerComponent {

  buildIsUpToDate$: Observable<boolean>;

  constructor(private buildDetailsService: BuildDetailsService) {
    this.buildIsUpToDate$ = this.buildDetailsService.buildIsUpToDate$;
  }

  reload(): void {
    location.reload();
  }
}
