<nb-card [class.notification-list]="!isInDashboard" size="medium" style="min-width: 23rem">
  <nb-card-header>
    <div class="row">
      <div class="col">
        <h6 translate="notification.title"></h6>
      </div>
      <div class="col">
        <span class="subtitle-2 highlight-text float-right mark-as-read" (click)="markAllAsRead()" translate="notification.markAllAsRead"></span>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <nb-alert *ngIf="notifications && notifications.length === 0" translate="notification.noNotificationsAlert" status="basic" class="no-notification-banner"></nb-alert>
    <nb-list *ngIf="notifications">
      <nb-list-item class="notification" *ngFor="let notification of notifications"
                    (click)="clickOnNotification(notification)">
        <div class="row full-width">
          <div class="col-2 my-auto">
            <ng-container [ngSwitch]="notification.type">
                  <span *ngSwitchCase="eNotificationType.TASK_RELEASED" class="subtitle-2 has-custom-badge">
                    <button nbButton status="basic">
                      <nb-icon icon="file-text-outline"></nb-icon>
                    </button>
                    <div class="custom-badge" *ngIf="!notification.read"></div>
                  </span>
              <span *ngSwitchCase="eNotificationType.CHAPTER_RELEASED" class="subtitle-2 has-custom-badge">
                <button nbButton status="basic">
                  <nb-icon icon="book-open-outline"></nb-icon>
                </button>
              <div class="custom-badge" *ngIf="!notification.read"></div>
                  </span>
              <span *ngSwitchCase="eNotificationType.TEAM_INVITATION_RECEIVED" class="subtitle-2 has-custom-badge">
                <nb-user shape="rectangle"
                         [name]="notificationService.toTeamInvitationReceivedNotification(notification).creatorUser.fullName"
                         [picture]="notificationService.toTeamInvitationReceivedNotification(notification).creatorUser.profilePicturePreviewUrl"
                         onlyPicture></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.ADDED_TO_COURSE" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toAddedToCourseNotification(notification).creatorUser.fullName"
                             [picture]="notificationService.toAddedToCourseNotification(notification).creatorUser.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.MODIFIED_COURSE_ROLE" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toAddedToCourseNotification(notification).creatorUser.fullName"
                             [picture]="notificationService.toAddedToCourseNotification(notification).creatorUser.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.REVIEW_APPROVED" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toReviewNotification(notification).review.reviewer.name"
                             [picture]="notificationService.toReviewNotification(notification).review.reviewer.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.REVIEW_DECLINED" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toReviewNotification(notification).review.reviewer.name"
                             [picture]="notificationService.toReviewNotification(notification).review.reviewer.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.REVIEW_CHANGES_REQUESTED" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toReviewNotification(notification).review.reviewer.name"
                             [picture]="notificationService.toReviewNotification(notification).review.reviewer.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.REVIEW_RE_REQUESTED" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toReviewNotification(notification).review.receiver.name"
                             [picture]="notificationService.toReviewNotification(notification).review.receiver.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.REVIEW_ACKNOWLEDGED" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toReviewNotification(notification).review.receiver.name"
                             [picture]="notificationService.toReviewNotification(notification).review.receiver.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.TASK_DEADLINE" class="subtitle-2 has-custom-badge">
                    <button nbButton status="basic">
                      <nb-icon icon="alert-triangle-outline"></nb-icon>
                    </button>
                    <div class="custom-badge" *ngIf="!notification.read"></div>
                  </span>
              <span *ngSwitchCase="eNotificationType.EVENT_UPCOMING" class="subtitle-2 has-custom-badge">
                    <button nbButton status="basic">
                      <nb-icon icon="people-outline"></nb-icon>
                    </button>
                    <div class="custom-badge" *ngIf="!notification.read"></div>
                  </span>
              <span *ngSwitchCase="eNotificationType.APPLICATION_REVIEW_ASSIGNED" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toApplicationReviewNotification(notification).creatorUser.fullName"
                             [picture]="notificationService.toApplicationReviewNotification(notification).creatorUser.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.PERSON_ASSIGNED" class="subtitle-2 has-custom-badge">
                    <nb-user shape="rectangle"
                             [name]="notificationService.toPersonAssignedNotification(notification).creatorUser.fullName"
                             [picture]="notificationService.toPersonAssignedNotification(notification).creatorUser.profilePicturePreviewUrl"
                             onlyPicture color="basic"></nb-user>
              <span class="custom-badge user-badge" *ngIf="!notification.read"></span>
                  </span>
              <span *ngSwitchCase="eNotificationType.REGISTRATION_STATE_UPDATE" class="subtitle-2 has-custom-badge">
                    <button nbButton status="basic">
                      <nb-icon icon="layers-outline"></nb-icon>
                    </button>
                    <div class="custom-badge" *ngIf="!notification.read"></div>
                  </span>
            </ng-container>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-8">
                <ng-container [ngSwitch]="notification.type">
                  <span *ngSwitchCase="eNotificationType.TASK_RELEASED" class="subtitle-2">
                    {{notificationService.toTaskReleasedNotification(notification).creatorChapter.title}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.CHAPTER_RELEASED" class="subtitle-2">
                    {{notificationService.toChapterReleasedNotification(notification).creatorCourse.name}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.TEAM_INVITATION_RECEIVED" class="subtitle-2">
                    {{notificationService.toTeamInvitationReceivedNotification(notification).creatorUser.fullName}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.ADDED_TO_COURSE" class="subtitle-2">
                    {{notificationService.toAddedToCourseNotification(notification).creatorUser.fullName}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.MODIFIED_COURSE_ROLE" class="subtitle-2">
                    {{notificationService.toAddedToCourseNotification(notification).creatorUser.fullName}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_APPROVED" class="subtitle-2">
                    {{notificationService.toReviewNotification(notification).review.reviewer.name}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_DECLINED" class="subtitle-2">
                    {{notificationService.toReviewNotification(notification).review.reviewer.name}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_CHANGES_REQUESTED" class="subtitle-2">
                    {{notificationService.toReviewNotification(notification).review.reviewer.name}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_RE_REQUESTED" class="subtitle-2">
                    {{notificationService.toReviewNotification(notification).review.receiver.name}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_ACKNOWLEDGED" class="subtitle-2">
                    {{notificationService.toReviewNotification(notification).review.receiver.name}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.TASK_DEADLINE" class="subtitle-2">
                    {{notificationService.toTaskReleasedNotification(notification).task.title}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.EVENT_UPCOMING" class="subtitle-2">
                    {{notificationService.toEventUpcomingNotification(notification).task.title}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.APPLICATION_REVIEW_ASSIGNED" class="subtitle-2">
                    {{notificationService.toApplicationReviewNotification(notification).creatorUser.fullName}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.PERSON_ASSIGNED" class="subtitle-2">
                    {{notificationService.toPersonAssignedNotification(notification).creatorUser.fullName}}
                  </span>
                  <span *ngSwitchCase="eNotificationType.REGISTRATION_STATE_UPDATE" class="subtitle-2">
                    {{notificationService.toRegistrationUpdatedStateNotification(notification).registrationProcessName}}
                  </span>
                </ng-container>
              </div>
              <div class="col-4">
                <span class="text-hint subtitle-2 float-right">{{notification.createdAt | relativeTime}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 my-auto">
                <ng-container [ngSwitch]="notification.type">
                <span *ngSwitchCase="eNotificationType.TASK_RELEASED" class="paragraph">
                  <span translate="notification.released"></span>
                  <strong class="highlight-text">
                    {{notificationService.toTaskReleasedNotification(notification).task.title}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.CHAPTER_RELEASED" class="paragraph">
                  <span translate="notification.released"></span>
                  <strong class="highlight-text">
                    {{notificationService.toChapterReleasedNotification(notification).chapter.title}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.TEAM_INVITATION_RECEIVED" class="paragraph">
                  <span translate="notification.invitedToTeam"></span>
                  <strong class="highlight-text">
                    {{notificationService.toTeamInvitationReceivedNotification(notification).invitationFrom.name}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.ADDED_TO_COURSE" class="paragraph">
                  <span translate="notification.addedTo"></span>
                  <strong class="highlight-text">
                    {{notificationService.toAddedToCourseNotification(notification).course.name}}
                  </strong>
                  <span translate="notification.asRole"></span>
                  <strong class="highlight-text"
                        [translate]="'roles.' + notificationService.toAddedToCourseNotification(notification).accessLevel">
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.MODIFIED_COURSE_ROLE" class="paragraph">
                  <span translate="notification.changedTo"></span>
                  <strong class="highlight-text">
                    {{notificationService.toAddedToCourseNotification(notification).course.name}}
                  </strong>
                  <span translate="notification.to"></span>
                  <strong class="highlight-text"
                        [translate]="'roles.' + notificationService.toAddedToCourseNotification(notification).accessLevel">
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_APPROVED" class="paragraph">
                  <span translate="notification.review.approved"></span>
                  <strong class="highlight-text">
                    {{notificationService.toReviewNotification(notification).review.submission.task.title}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_DECLINED" class="paragraph">
                  <span translate="notification.review.declined"></span>
                  <strong class="highlight-text">
                    {{notificationService.toReviewNotification(notification).review.submission.task.title}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_CHANGES_REQUESTED" class="paragraph">
                  <span translate="notification.review.changesRequested"></span>
                  <strong class="highlight-text">
                    {{notificationService.toReviewNotification(notification).review.submission.task.title}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_RE_REQUESTED" class="paragraph">
                  <span translate="notification.review.reRequested"></span>
                  <strong class="highlight-text">
                    {{notificationService.toReviewNotification(notification).review.submission.task.title}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.REVIEW_ACKNOWLEDGED" class="paragraph">
                  <span translate="notification.review.acknowledged"></span>
                  <strong class="highlight-text">
                    {{notificationService.toReviewNotification(notification).review.submission.task.title}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.TASK_DEADLINE" class="paragraph">
                  <span translate="notification.deadline"></span>
                  <strong class="highlight-text">
                    {{notificationService.toTaskDeadlineNotification(notification).task.endDate | date: 'short'}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.EVENT_UPCOMING" class="paragraph">
                  <span translate="notification.event"></span>
                  <strong class="highlight-text">
                    {{notificationService.toEventUpcomingNotificationEvent(notification).eventDate | date: 'short'}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.APPLICATION_REVIEW_ASSIGNED" class="paragraph">
                  <span translate="notification.assignedApplicationReview"></span>
                  <strong class="highlight-text">
                    {{notificationService.toApplicationReviewNotification(notification).applicationReview.applicationRoundResult.applicant.name}}
                  </strong>
                  <span translate="notification.in"></span>
                  <strong class="highlight-text">
                    {{notificationService.toApplicationReviewNotification(notification).applicationReview.applicationRoundResult.applicant.applicationProcess.name}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.PERSON_ASSIGNED" class="paragraph">
                  <span translate="notification.offeredToClaim"></span>
                  <strong class="highlight-text">
                    {{notificationService.toPersonAssignedNotification(notification).person.fullName}}
                  </strong>
                </span>
                  <span *ngSwitchCase="eNotificationType.REGISTRATION_STATE_UPDATE" class="paragraph">
                  <span translate="notification.registrationStateUpdated"></span>
                  <strong class="highlight-text">
                    {{'registration.state.' + notificationService.toRegistrationUpdatedStateNotification(notification).registrationState | translate}}
                  </strong>
                </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </nb-list-item>
    </nb-list>
    <!-- TODO: Make this look nice -->
    <div (click)="includeReadNotifications = true; loadNotifications()" [hidden]="includeReadNotifications"
          class="subtitle text-hint load-indicator">
        <span translate="notification.showReadNotifications"></span>
        <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
      </div>
    <nb-list-item *ngFor='let _ of placeholders'>
      <div class='row w-100'>
        <div class='col-12 text my-auto'>
          <div class='placeholder-notification'></div>
        </div>
      </div>
    </nb-list-item>
  </nb-card-body>
</nb-card>
