import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Inject, Injectable, NgZone } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { AUTH_STRATEGY, StatusCode, TEAM_IDENTIFIER } from '../shared/constants';
import { Router } from '@angular/router';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import {
  AlternativeCourseMemberSelectDialogComponent
} from '../auth/alternative-course-member-select-dialog/alternative-course-member-select-dialog.component';
import { CourseMember } from '../course-member/course-member.model';
import { UserService } from '../user/user.service';
import { DialogService } from '../layout/dialog/dialog.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: NbTokenService,
    private nbAuthService: NbAuthService,
    private dialogService: DialogService,
    private toastrService: NbToastrService,
    private router: Router,
    private userService: UserService,
    @Inject(NgZone) private zone: NgZone
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An unknown error occurred';
        console.log(error);
        if (error.error?.message) {
          errorMessage = error.error.message;

        // Check for error messages for nebular/akveo components
        } else if (error.error.data?.errors?.length > 0) {
          errorMessage = error.error.data.errors[0];
        }
        if (error.status === StatusCode.INVALID_TEAM) {
          // Invalid team used -> Reset team
          localStorage.removeItem(TEAM_IDENTIFIER);
          this.userService.reloadUserInfo();
          this.router.navigate(['/dashboard']);
          return;
        }

        if (error.status === 403) {
          if (error.error?.route) {
            this.router.navigate([error.error.route], {fragment: error.error.fragment});
          } else if (error.error?.alternativeCourseMembers) {
            const alternativeCourseMembers: CourseMember[] = error.error.alternativeCourseMembers;
            if (alternativeCourseMembers.length === 0) {
              // Nothing we can do here
              this.router.navigate(['/dashboard']);

            } else if (alternativeCourseMembers.length === 1) {
              // Switch to alternative and reload page
              const teamId = alternativeCourseMembers[0].team?.id;
              if (teamId) {
                localStorage.setItem(TEAM_IDENTIFIER, teamId);
              } else {
                localStorage.removeItem(TEAM_IDENTIFIER);
              }

              this.userService.reloadUserInfo();
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigateByUrl(location.pathname);
              return throwError(() => error);

            } else {
              this.zone.run(()=> {
                // Open dialog
                this.dialogService.open(AlternativeCourseMemberSelectDialogComponent, {
                  context: {
                    courseMembers: alternativeCourseMembers,
                    targetPath: location.pathname,
                  },
                  closeOnBackdropClick: false,
                  closeOnEsc: false
                });
              });
              return throwError(() => error);
            }
          } else {
            this.router.navigate(['/dashboard']);
          }
        }

        if (!error.url.endsWith('/api/oidc/provider/token')) {
          // Only show error if it is not caused by token refreshing
          // TODO: Fix this properly
          this.toastrService.show(errorMessage, 'An error occurred', {
            status: 'danger',
            duration: 10000,
          });
        }

        if (error.status === 401) {
          this.nbAuthService.logout(AUTH_STRATEGY);
          this.tokenService.clear();
          this.nbAuthService.authenticate(AUTH_STRATEGY, {state: window.location.pathname});
        }

        return throwError(() => error);
      })
    );
  }
}
