import { Component, OnDestroy } from '@angular/core';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AUTH_STRATEGY } from '../../shared/constants';
import { TeamService } from '../../team/team.service';

@Component({
  selector: 'app-callback',
  template: ``,
})
export class CallbackComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    private nbAuthService: NbAuthService,
    private router: Router,
    private teamService: TeamService,
    route: ActivatedRoute
  ) {
    // State contains requested path, if applicable -> Redirect user to this path, if present
    let requestedPath = route.snapshot.queryParamMap.get('state'); // Can be null

    // Check if a teamId is given and select this team (used when an user is invited to a team so that the user is in the team mode instantly)
    const teamId = route.snapshot.queryParamMap.get('team');
    if (teamId) {
      this.teamService.setTeamIdentifier(teamId);
    }

    const applicationProcess = route.snapshot.queryParamMap.get('applicationProcess');
    if (applicationProcess && !requestedPath) {
      requestedPath = `/application/${applicationProcess}/apply`;
    }

    this.nbAuthService.authenticate(AUTH_STRATEGY)
      .pipe(takeUntil(this.destroy$))
      .subscribe((authResult: NbAuthResult) => {
        if (authResult.isSuccess()) {
          if (requestedPath) {
            this.router.navigateByUrl(requestedPath);
          } else {
            this.router.navigateByUrl('/dashboard');
          }
        } else {
          console.log('error');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
