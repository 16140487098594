import { Team } from '../team/team.model';
import { CourseMember } from '../course-member/course-member.model';
import { TeamInvitation } from '../team/team-invitation.model';
import { SkillCategory } from '../skill/skill.model';
import { File } from '../file/file.model';

export enum UserRole {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  INSTRUCTOR = 'instructor',
  COACH = 'coach',
  USER = 'user',
  GUEST = 'guest'
}

export enum PersonRole {
  STUDENT = 'student',
  FOUNDER = 'founder',
  COACH = 'coach',
  MENTOR = 'mentor',
  EXPERT = 'expert',
  INVESTOR = 'investor',
}

export interface NotificationProviderSettings {
  teamInvitation?: boolean;
  addedToCourseOrRoleModified?: boolean;
  offeredToClaimPerson?: boolean;
  registrationUpdate?: boolean;
}

export interface NotificationSettings {
  email?: NotificationProviderSettings;
}

export interface Person {
  id?: string;
  name: string;
  surname: string;
  email?: string;

  about?: string;
  title?: string;
  category?: SkillCategory[];
  role: PersonRole[];
  social?: string;
  website?: string;
  bookingLink?: string;
  picture?: File;
  readonly profilePicturePreviewUrl?: string;
  readonly isExternal?: boolean;
  readonly fileTenantId?: string;
  readonly fullName?: string; // Virtual field

  pendingAssignedUser?: User; // Only set for externalPersons

  pictureLink?: string; // only set & used in client for now
}

export interface User extends Person {
  readonly s3ProfilePictureKey?: string; // Virtual field
  activatedTenants?: { [key: string]: { role: UserRole, activationDate?: Date, lastAcceptedPolicyVersion: number } };
  activationToken?: string;

  courseMembers?: CourseMember[];
  readonly skillsManagerIds?: string[]; // Ids of skills where current user is manager - empty if team mode is active
  teams?: Team[];
  completedTasks?: string[]; // Ids of completed tasks
  teamInvitations?: TeamInvitation[];
  notificationSettings?: NotificationSettings;

  currentName?: string; // Current user/team name -> Only set in client
  currentProfilePicturePreviewUrl?: string; // Current user/team profile picture preview url -> Only set in client
  isTeam?: boolean; // Indicates whether current profile is team -> Only set in client
  currentTeamId?: string; // Indicates whether current profile is team -> Only set in client
}
