import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NbAuthService } from '@nebular/auth';
import { NbMenuService, NbPopoverDirective } from '@nebular/theme';
import { Router } from '@angular/router';
import { NbAccessChecker } from '@nebular/security';
import { UserService } from '../../user/user.service';
import { AUTH_STRATEGY, OAUTH_CLIENT_ID, USER_IDENTIFIER } from '../../shared/constants';
import { ManagementService } from '../../core/services/management.service';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { environment } from '../../../environments/environment';
import { TeamService } from '../../team/team.service';
import { User } from '../../user/user.model';
import { TeamActivateDialogComponent } from '../../team/team-activate-dialog/team-activate-dialog.component';
import { Tenant } from '../../tenant/tenant.model';
import { Team } from '../../team/team.model';
import { LayoutService } from '../layout.service';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  user$: Observable<User>;
  shouldShowLoginButtons$: Observable<boolean>;
  tenant: Tenant;
  isLargeScreen = true;
  isSmallScreen = false;
  isSuperSmallScreen = false;
  authenticated = false;

  private authListener$: Subscription;

  constructor(
    protected authService: NbAuthService,
    private menuService: NbMenuService,
    private userService: UserService,
    private managementService: ManagementService,
    private router: Router,
    private nbAccessChecker: NbAccessChecker,
    private matomoTracker: MatomoTracker,
    private teamService: TeamService,
    private dialogService: DialogService,
    private layoutService: LayoutService,
  ) {
    this.authListener$ = authService
      .onAuthenticationChange()
      .subscribe((authenticated) => {
        this.authenticated = authenticated;

        // Only load this when authenticated and not on logout page, as you are logged out there too
        if (authenticated && window.location.pathname !== '/auth/logout') {
          this.userService.reloadUserInfo();
        }
      });
    this.user$ = this.userService.userData$;
    this.shouldShowLoginButtons$ = this.layoutService.shouldShowLoginButtons$;
    this.tenant = managementService.tenant;
  }

  @HostListener('window:resize', []) updateEvents(): void {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.getScreenSize();
  }

  getScreenSize(): void {
    this.isLargeScreen = window.innerWidth >= 768;
    this.isSmallScreen = window.innerWidth < 576;
    this.isSuperSmallScreen = window.innerWidth < 420;
  }

  onMenuClick(id: string): void {
    this.matomoTracker.trackEvent('menu', 'menuItemClick', id);
    switch (id) {
      case 'dashboard':
        this.router.navigate(['/dashboard']);
        break;
      case 'logout':
        const urlParameters = new URLSearchParams({
          client_id: OAUTH_CLIENT_ID,
          post_logout_redirect_uri: location.protocol + '//' + location.host + '/auth/logout',
        });

        location.href = environment.ssoUrl + '/api/oidc/provider/session/end?' + urlParameters.toString();
        break;
      case 'userProfile':
        this.router.navigate(['/user/profile']);
        break;
      case 'teamProfile':
        this.router.navigate(['/team/profile']);
        break;
      case 'team':
        this.router.navigate(['/team']);
        break;
      case 'joinTeam':
        this.router.navigate(['/team', 'join']);
        break;
      case 'program':
        this.router.navigate(['/program']);
        break;
      case 'skill':
        this.router.navigate(['/skill']);
        break;
      case 'admin':
        this.router.navigate(['/administration']);
        break;
      case 'tenantDashboard':
        this.router.navigate(['/tenant']);
        break;
      case 'persons':
        this.router.navigate(['/community']);
        break;
      default:
    }
    this.popover.hide();
  }

  ngOnDestroy(): void {
    this.authListener$.unsubscribe();
  }

  login(): void {
    this.authService.authenticate(AUTH_STRATEGY)
      .subscribe(() => {
        // We have to subscribe to make the call
      });
  }

  register(): void {
    const tenantId = this.managementService.tenant.id;
    location.href = `${environment.ssoUrl}/register/${tenantId}`;
  }

  selectUser() {
    this.teamService.removeTeamIdentifier();
    this.router.navigate(['/dashboard']);
    this.popover.hide();
  }

  selectTeam(team: Team) {
    const isAlreadyActivatedForTenant = team.activatedTenants[this.tenant.id];

    if (isAlreadyActivatedForTenant) {
      this.teamService.setTeamIdentifier(team.id);
      this.router.navigate(['/dashboard']);
      this.popover.hide();
      return;
    }

    this.dialogService.open(TeamActivateDialogComponent, {
      context: {
        team,
        activated: () => {
          this.teamService.setTeamIdentifier(team.id);
          this.router.navigate(['/dashboard']);
        },
      }
    });
  }
}
