import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutComponent } from './logout/logout.component';
import { NebularModule } from '../nebular.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { WaitForConfirmationComponent } from './wait-for-confirmation/wait-for-confirmation.component';
import { CallbackComponent } from './callback/callback.component';
import { InviteAcceptComponent } from './invite-accept/invite-accept.component';
import { IsGrantedInCourseDirective } from './is-granted-in-course.directive';
import { UtilityModule } from '../utility/utility.module';
import { RoleUpdatedBannerComponent } from './role-updated-banner/role-updated-banner.component';
import { AlternativeCourseMemberSelectDialogComponent } from './alternative-course-member-select-dialog/alternative-course-member-select-dialog.component';
import { IsSkillManagerDirective } from './is-skill-manager.directive';

@NgModule({
  imports: [
    NebularModule,
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    TranslateModule,
    UtilityModule,
  ],
  declarations: [
    CallbackComponent,
    LogoutComponent,
    IsGrantedInCourseDirective,
    IsSkillManagerDirective,
    ChangePasswordComponent,
    WaitForConfirmationComponent,
    InviteAcceptComponent,
    RoleUpdatedBannerComponent,
    AlternativeCourseMemberSelectDialogComponent,
  ],
    exports: [IsGrantedInCourseDirective, IsSkillManagerDirective, RoleUpdatedBannerComponent],
})
export class AuthModule {}
